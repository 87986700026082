import { Box, Pagination, Skeleton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FastField, Form, Formik, useFormikContext } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAlerts } from "../../context/AlertContext";
import AutoSubmitOnChange from "../form/AutoSubmitOnChange";
import FormSelect from "../form/FormSelect";
import LoadingOverlay from "./LoadingOverlay";

const PaginatedSearchResults = forwardRef(
  (
    {
      fetchSearchResults,
      ResultDisplayComponent,
      defaultSearchCriteria,
      SearchCriteriaComponent,
      onClickSearchResult,
      normalizeSearchCriteria,
      validationSchema,
      ...props
    },
    ref
  ) => {
    const [searchCriteria, setSearchCrit] = useState({
      ...defaultSearchCriteria,
    });
    const [loading, setLoading] = useState(true);
    const [searchResults, setSearchResults] = useState();
    const formikRef = useRef(null);
    const { addErrorAlert } = useAlerts();
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
      // This can be called from parent components to trigger a re-run of the search
      rerunSearch: () => {
        setSearchCrit({ ...searchCriteria });
      },
    }));

    function setSearchCriteria(newCriteria) {
      // If the criteria that changes is NOT page number, reset the page to 1
      if (newCriteria.page !== searchCriteria.page) {
        setSearchCrit(newCriteria);
      } else {
        setSearchCrit({ ...newCriteria, page: 1 });
      }
    }

    useEffect(() => {
      setLoading(true);
      const normalizedSearchCriteria = normalizeSearchCriteria
        ? normalizeSearchCriteria(searchCriteria)
        : searchCriteria;
      navigate(undefined, {
        state: {
          lastSearchedCriteria: { ...normalizedSearchCriteria },
        },
        replace: true,
      });
      fetchSearchResults({ ...normalizedSearchCriteria })
        .then((response) => {
          setSearchResults(response);
          setLoading(false);
        })
        .catch((err) => {
          addErrorAlert("Error completing request", err);
        });
      formikRef.current.resetForm({ values: searchCriteria });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCriteria, fetchSearchResults, navigate, addErrorAlert]);

    const numPagesTotal =
      searchResults?.length > 0
        ? Math.ceil(
            (1.0 * searchResults[0].totalCount) / searchCriteria.itemsPerPage
          )
        : undefined;

    const ResultPagination = ({ itemsPerPageOptions, sx, ...props }) => {
      const formik = useFormikContext();
      return (
        <Grid
          container
          justifyContent={"end"}
          alignItems="center"
          sx={{
            m: 1,
          }}
          {...props}
        >
          <Grid>
            <Pagination
              name="page"
              count={numPagesTotal}
              page={searchCriteria.page}
              onChange={(p, v) => {
                formik.handleChange({ target: { name: "page", value: v } });
              }}
              size="small"
              data-testid="pagination"
            />
          </Grid>
          <Grid>
            <FastField
              as={FormSelect}
              name="itemsPerPage"
              label="# per page"
              style={{ width: 100 }}
              options={
                itemsPerPageOptions || [
                  {
                    label: "10",
                    value: 10,
                  },
                  {
                    label: "25",
                    value: 25,
                  },
                  {
                    label: "50",
                    value: 50,
                  },
                ]
              }
              data-testid="items-per-page-select"
            />
          </Grid>
        </Grid>
      );
    };

    return (
      <Box
        style={{ textAlign: "start", display: "flex", flexDirection: "column" }}
        ref={ref}
        data-testid="paginated-search-results"
      >
        <Formik
          onSubmit={setSearchCriteria}
          initialValues={defaultSearchCriteria}
          innerRef={formikRef}
          validationSchema={validationSchema}
        >
          {() => {
            return (
              <Form style={{ position: "relative" }} data-testid="search-form">
                {/* When the form changes, automatically submit the search */}
                <AutoSubmitOnChange />
                <SearchCriteriaComponent />
                <ResultPagination
                  sx={{ mb: 0.5, mt: 0.5 }}
                  {...props.PaginationProps}
                />
                <Box
                  id="search-results"
                  {...props.SearchResultsProps}
                  sx={{ position: "relative", ...props.SearchResultsProps?.sx }}
                  data-testid="search-results-box"
                >
                  {searchResults?.length > 0 ? (
                    <>
                      {loading && <LoadingOverlay />}
                      {searchResults.map((item) => (
                        <ResultDisplayComponent
                          key={item.id}
                          item={item}
                          onClick={
                            onClickSearchResult
                              ? () => onClickSearchResult(item)
                              : undefined
                          }
                        />
                      ))}
                    </>
                  ) : searchResults?.length === 0 ? (
                    <p data-testid="no-results">No results found</p>
                  ) : (
                    <>
                      <Skeleton
                        variant="rectangular"
                        sx={{ m: 0.25 }}
                        data-testid="skeleton-1"
                      />
                      <Skeleton
                        variant="rectangular"
                        sx={{ m: 0.25 }}
                        data-testid="skeleton-2"
                      />
                      <Skeleton
                        variant="rectangular"
                        sx={{ m: 0.25 }}
                        data-testid="skeleton-3"
                      />
                    </>
                  )}
                </Box>
                {searchResults?.length > 10 && (
                  <ResultPagination
                    sx={{ mt: 0.5 }}
                    {...props.PaginationProps}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    );
  }
);

export default PaginatedSearchResults;
