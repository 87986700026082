import axios from "axios";

export const CATEGORIES_BASE_URL =
  process.env.REACT_APP_API_PATH + "/categories";

const CategoryService = {
  async getCategories() {
    const response = await axios.get(CATEGORIES_BASE_URL);
    return response.data;
  },
  async createCategory(category) {
    const response = await axios.post(CATEGORIES_BASE_URL, category);
    return response.data;
  },
};

export default CategoryService;
