import { Box, Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField";
import SearchCriteriaColorPicker from "../../../common/components/form/SearchCriteriaColorPicker";

export default function PresaleInventorySearchCriteria() {
  return (
    <Paper sx={{ position: "sticky", top: 0, zIndex: 100, p: 1 }} elevation={0}>
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
        <Field
          as={DebouncedTextField}
          label="Search..."
          name="searchText"
          sx={{ maxWidth: 300, width: "100%" }}
          size="small"
        />
        <Field
          as={SearchCriteriaColorPicker}
          label="Color"
          name="colors"
          size="small"
        />
      </Box>
    </Paper>
  );
}
