import { Chip, Tooltip } from "@mui/material";
import React from "react";

export default function ProductQuantityWithFulfillmentChip({ product }) {
  return (
    <Tooltip
      title={`${product.quantityFulfilled} items fulfilled of ${product.quantityRequested} requested`}
    >
      <Chip
        sx={{ ml: 1, mr: 1, width: "fit-content", textAlign: "end" }}
        data-testid="product-instance-quantity"
        size="small"
        label={`${product.quantityFulfilled}/${product.quantityRequested}`}
        color={
          product.quantityFulfilled &&
          product.quantityFulfilled === product.quantityRequested
            ? "success"
            : product.quantityFulfilled === undefined
              ? "default"
              : "info"
        }
      />
    </Tooltip>
  );
}
