import Grid from "@mui/material/Unstable_Grid2/Grid2.js";
import { FastField, Field } from "formik";
import React from "react";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField.jsx";
import FormCheckboxWithLabel from "../../../common/components/form/FormCheckboxWithLabel.jsx";
import FormSelect from "../../../common/components/form/FormSelect.jsx";

const BusinessSearchCriteria = () => {
  return (
    <Grid container sx={{ mb: 1 }} spacing={3} direction="row">
      <Grid>
        <Field as={DebouncedTextField} label="Search..." name="searchText" />
      </Grid>
      <Grid>
        <FastField
          as={FormCheckboxWithLabel}
          name="isBuyer"
          label="Only show buyers"
        />
      </Grid>
      <Grid>
        <FastField
          as={FormCheckboxWithLabel}
          name="isSupplier"
          label="Only show suppliers"
        />
      </Grid>
      <Grid>
        <FastField
          as={FormCheckboxWithLabel}
          name="isPlatinum"
          label={"Only show platinum members"}
        />
      </Grid>
      <Grid xs="auto">
        <FastField
          as={FormSelect}
          name="activeStatus"
          label="Business Status"
          options={[
            { value: "", label: "All" },
            { value: "active", label: "Active" },
            { value: "inactive", label: "Inactive" },
          ]}
          sx={{ minWidth: "150px" }}
        />
      </Grid>
    </Grid>
  );
};

export default BusinessSearchCriteria;
