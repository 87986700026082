import { Box } from "@mui/material";
import React from "react";
import { usePresaleOrderContext } from "./PresaleOrderFormContext";

export default function CartTimer({ ...props }) {
  const { timeRemainingText } = usePresaleOrderContext();
  if (!timeRemainingText) {
    return null;
  }
  return (
    <Box
      style={{
        backgroundColor: "#f44336",
        color: "white",
        padding: "8px",
        textAlign: "center",
      }}
      data-testid="cart-timer"
    >
      Cart expires in {timeRemainingText}
    </Box>
  );
}
