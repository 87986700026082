import { Skeleton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import LoadingOverlay from "../../../common/components/data-display/LoadingOverlay";
import FlowerQualitySelect from "../../../common/components/form/FlowerQualitySelect";
import FormFlowerColorPicker from "../../../common/components/form/FormFlowerColorPicker";
import FormSelect from "../../../common/components/form/FormSelect";
import { FormTextField } from "../../../common/components/form/FormTextField";
import { useAlerts } from "../../../common/context/AlertContext";
import { useCollective } from "../../../common/context/CollectiveContext";
import { useLocalStorage } from "../../../common/hooks/useLocalStorage";
import BaseProductService from "../../../common/service/BaseProductService";
import StockLogService from "../../../common/service/StockLogService";
import {
  isPresaleUploadingAvailableForDate,
  isPrimaryMarketDay,
} from "../../../common/util/DatePickerUtilFrontend";
import { displayPrice } from "../../../common/util/DisplayUtil";
import ProductPrice from "../../AdvancedRequests/ProductPrice";

export const FLOWER_QUALITIES = ["Basic", "Standard", "Premium"];
export const FLOWER_QUALITIES_NO_BASIC = ["Standard", "Premium"];
export const SALE_FOCUSES = ["Pre-Sale", "Speculation", "Advanced Order"];

function getDefaultValues(baseProduct, productInstance, lastSaleFocus) {
  if (baseProduct) {
    return {
      attributes: {
        primaryColor: "",
        secondaryColor: "",
        quality: FLOWER_QUALITIES[1],
      },
      saleFocus: lastSaleFocus || SALE_FOCUSES[0],
      name: baseProduct.name,
      quantity: 1,
      baseProductId: baseProduct.id,
      estimatedUnitPrice: baseProduct.minPrice || baseProduct.maxPrice,
    };
  } else if (productInstance) {
    return {
      attributes: {
        primaryColor:
          productInstance.attributes.find(
            (attr) => attr.attributeName === "PrimaryColor"
          )?.attributeValue || "",
        secondaryColor:
          productInstance.attributes.find(
            (attr) => attr.attributeName === "SecondaryColor"
          )?.attributeValue || "",

        quality:
          productInstance.attributes.find(
            (attr) => attr.attributeName === "Quality"
          )?.attributeValue || FLOWER_QUALITIES[1],
      },
      saleFocus: productInstance.saleFocus,
      name: productInstance.name,
      quantity: productInstance.quantity || 1,
      baseProductId: productInstance.baseProductId,
      estimatedUnitPrice: productInstance.estimatedUnitPrice,
      dateStocked: productInstance.dateStocked,
    };
  }
}

export default function UploadInventoryDialog({
  isOpen,
  onClose,
  supplierBusiness,
  baseProduct,
  productInstance,
  onAdd,
  onUpdate,
  onDelete,
  marketDate,
}) {
  const [lastSaleFocus, setLastSaleFocus] = useLocalStorage(
    "LAST_SALE_FOCUS",
    SALE_FOCUSES[0]
  );
  const { collectiveInfo } = useCollective();
  const { addErrorAlert } = useAlerts();
  const [baseProductDetails, setBaseProductDetails] = React.useState();
  const formikRef = React.useRef(null);
  const dateStocked = moment(marketDate || productInstance?.dateStocked);
  React.useEffect(() => {
    if ((productInstance || baseProduct) && formikRef.current) {
      formikRef.current.resetForm(
        getDefaultValues(baseProduct, productInstance, lastSaleFocus)
      );
    }
    if (baseProduct || productInstance) {
      BaseProductService.getBaseProductById(
        baseProduct?.id || productInstance?.baseProductId,
        true
      )
        .then((baseProductDetails) => {
          setBaseProductDetails(baseProductDetails);
        })
        .catch((err) => {
          addErrorAlert("Error getting base product details", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseProduct, productInstance, addErrorAlert]);

  const handleDelete = async () => {
    formikRef.current.setSubmitting(true);
    try {
      const updatedReq = await StockLogService.deleteStockLog(
        productInstance.id
      );
      onDelete(updatedReq);
    } catch (err) {
      addErrorAlert("Error deleting product", err);
    }
    formikRef.current.setSubmitting(false);
  };

  const handleSubmit = async (values) => {
    setLastSaleFocus(values.saleFocus);
    if (productInstance) {
      const stockToUpdate = {
        supplierId: supplierBusiness?.id || productInstance.supplierId,
        saleFocus: values.saleFocus,
        quantity: values.quantity,
        id: productInstance.id,
      };
      try {
        const stockLog = await StockLogService.updateStockLog(stockToUpdate);
        onUpdate(stockLog);
      } catch (e) {
        addErrorAlert("Error updating product", e);
      }
    } else {
      const stockToAdd = {
        saleFocus: values.saleFocus,
        supplierId: supplierBusiness.id,
        dateStocked: marketDate.format("YYYY-MM-DD"),
        productInstance: {
          name: values.name,
          baseProductId: values.baseProductId,
          instanceAttributes: [
            {
              attributeName: "PrimaryColor",
              attributeValue: values.attributes.primaryColor,
            },
            {
              attributeName: "SecondaryColor",
              attributeValue: values.attributes.secondaryColor,
            },
            {
              attributeName: "Quality",
              attributeValue: values.attributes.quality,
            },
          ],
        },
        quantity: values.quantity,
        estimatedUnitPrice: values.estimatedUnitPrice,
      };
      try {
        const stockLog = await StockLogService.addStockLog(stockToAdd);
        onAdd(stockLog);
      } catch (e) {
        addErrorAlert("Error adding product", e);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Formik
        initialValues={getDefaultValues(
          baseProduct,
          productInstance,
          lastSaleFocus
        )}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={
          // Yup validation. Require positive quantity and a primary color
          Yup.object().shape({
            saleFocus: Yup.string()
              .required("Sale focus is required")
              .test(
                "presale-before-cutoff",
                `The window for pre-sale has closed for the ${dateStocked?.format("MMMM Do")} market`,
                (value) => {
                  return (
                    value !== "Pre-Sale" ||
                    isPresaleUploadingAvailableForDate(
                      dateStocked,
                      collectiveInfo
                    )
                  );
                }
              )
              .test(
                "presale-on-non-presale-day",
                `${dateStocked?.format("dddd MMM Do")} is not a pre-sale day`,
                (value) => {
                  return (
                    value !== "Pre-Sale" ||
                    isPrimaryMarketDay(dateStocked, collectiveInfo)
                  );
                }
              ),
            quantity: Yup.number()
              .required("Quantity is required")
              .positive("Enter a valid quantity")
              // When saleFocus is "Pre-Sale", then quantity must be at least 5
              .when("saleFocus", {
                is: "Pre-Sale",
                then: () =>
                  Yup.number().min(5, "Pre-Sale quantity must be at least 5"),
              }),
            attributes: Yup.object().shape({
              primaryColor: Yup.string().required("Primary color is required"),
            }),
          })
        }
      >
        {({ values, isSubmitting }) => (
          <Form>
            <DialogTitle>
              {baseProduct?.name || productInstance?.name}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ pt: 1, maxWidth: 250 }}>
                <Grid xs={12}>
                  <Field
                    as={FormSelect}
                    name="saleFocus"
                    label="Sale Focus"
                    options={SALE_FOCUSES}
                  />
                </Grid>
                {baseProductDetails ? (
                  <>
                    <Grid xs={12}>
                      <Field
                        as={FormFlowerColorPicker}
                        name="attributes.primaryColor"
                        label="Primary Color"
                        disabled={!!productInstance}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        as={FormFlowerColorPicker}
                        name="attributes.secondaryColor"
                        label="Secondary Color"
                        disabled={!!productInstance}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <FlowerQualitySelect
                        name="attributes.quality"
                        disabled={!!productInstance}
                        productDetails={baseProductDetails}
                        includeBasicQuality={false}
                      />
                    </Grid>
                    <Grid xsOffset={6} xs={6}>
                      <Field
                        as={FormTextField}
                        name="quantity"
                        type="number"
                        label="Quantity"
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                    </Grid>
                    <Grid xs={12} textAlign="end">
                      <ProductPrice
                        name="estimatedUnitPrice"
                        baseProductId={
                          baseProduct?.id || productInstance?.baseProductId
                        }
                        priceGroups={baseProductDetails.priceGroups}
                        fixedPrice={!!productInstance}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                )}
                <Grid xs={12} textAlign="end">
                  <Typography>
                    {values.estimatedUnitPrice && values.quantity && (
                      <strong>
                        Total:{" "}
                        {displayPrice(
                          values.estimatedUnitPrice * values.quantity
                        )}
                      </strong>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{ justifyContent: "space-between", position: "relative" }}
            >
              <Button onClick={onClose}>Cancel</Button>
              {productInstance && !productInstance.quantityFulfilled && (
                <Button color="error" onClick={handleDelete} variant="outlined">
                  Remove
                </Button>
              )}
              <Button color="primary" type="submit" variant="outlined">
                {!!productInstance ? `Update` : `Add`}
              </Button>
            </DialogActions>
            {isSubmitting && <LoadingOverlay />}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
