export const PermissionService = {
  isOwnerOfCollective: (collective, userWithRoles) => {
    if (!collective || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.businessId === collective.businessId && bizRole.role === "OWNER"
    );
  },
  isMemberOfCollective: (collective, userWithRoles) => {
    if (!collective || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.businessId === collective.businessId
    );
  },
  isBusinessSupplier: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.businessId === businessId && bizRole.supplierStatus === "yes"
    );
  },
  isBusinessBuyer: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.businessId === businessId && bizRole.buyerStatus === "yes"
    );
  },
  isOwnerOfBusiness: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.businessId === businessId && bizRole.role === "OWNER"
    );
  },
  isMemberOfBusiness: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.businessId === businessId
    );
  },
  isMemberOfAnyBuyer: (userWithRoles) => {
    if (!userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.buyerStatus === "yes"
    );
  },
  isMemberOfAnySupplier: (userWithRoles) => {
    if (!userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.supplierStatus === "yes"
    );
  },
};
