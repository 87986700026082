import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Field, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { BusinessUserRoles } from "../../../pages/Admin/ManageBusinesses/BusinessUserSummary";
import { useAlerts } from "../../context/AlertContext";
import UserService from "../../service/UserService";
import FormSelect from "./FormSelect";
import { FormTextField } from "./FormTextField";

export default function AddUserToBusinessDialog({
  open,
  initialText,
  onAddUser,
  onCancel,
  businessId,
}) {
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const initialValues = {
    email: initialText?.includes("@") ? initialText : "",
    firstName: "",
    lastName: "",
    businessUser: {
      role: "EMPLOYEE",
      businessId,
    },
  };
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Add New User</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            const addedUser = await UserService.createUser(values);
            addSuccessAlert("User added");
            onAddUser(addedUser.businessUser);
          } catch (error) {
            addErrorAlert("Error adding user to business", error);
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required("Email is required"),
          firstName: Yup.string().required("First name is required"),
          lastName: Yup.string().required("Last name is required"),
        })}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container direction={"column"} spacing={2}>
                <Grid>
                  <Field
                    as={FormTextField}
                    label="Email"
                    name="email"
                    type="email"
                  />
                </Grid>
                <Grid>
                  <Field
                    as={FormTextField}
                    label="First Name"
                    name="firstName"
                  />
                </Grid>
                <Grid>
                  <Field as={FormTextField} label="Last Name" name="lastName" />
                </Grid>
                <Grid>
                  <Field
                    as={FormSelect}
                    label="Role"
                    name="businessUser.role"
                    options={BusinessUserRoles}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
              <Button disabled={isSubmitting} type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
