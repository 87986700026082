import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay";
import UserDisplay from "../../../common/components/data-display/UserDisplay";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary";
import ProductPriceDisplay from "../../../common/components/product/ProductPriceDisplay";
import ProductQuantityChip from "../../../common/components/product/ProductQuantityChip";
import { displayPrice } from "../../../common/util/DisplayUtil";
import { useSalesLogContext } from "./SalesLogContext";

export default function SalesLogDetails({ adminView = false }) {
  const { salesLogId } = useParams();
  const { salesLog } = useSalesLogContext(salesLogId);

  if (!salesLog) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const adminSummaryComponents = [
    // Only show the supplier logo and name if in admin view
    ({ product }) => (
      <BusinessDisplay
        sx={{ maxWidth: 150 }}
        business={product.supplierBusiness}
      />
    ),
    ProductQuantityChip,
    ProductPriceDisplay,
  ];

  const nonAdminSummaryComponents = [
    // Only show the supplier logo and name if in admin view
    ProductQuantityChip,
    ProductPriceDisplay,
  ];

  return (
    <Container sx={{ pt: 1.5 }}>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
        <Typography variant="h3" data-testid="sales-log-title">
          Sales Log - #{salesLog.invoiceNumber}
        </Typography>
      </Box>
      <Divider sx={{ m: 2 }} />
      <Grid container spacing={2} alignItems="start">
        <Grid
          item
          xs={12}
          md={4}
          container
          spacing={1.5}
          alignItems="start"
          justifyContent="start"
        >
          <Grid item xs={12} container>
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Sale Date:
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="body1" data-testid="sale-date">
                {moment(salesLog.saleDate).format("LL")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Buyer:
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <BusinessDisplay business={salesLog.buyerBusiness} isLink />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Cashier:
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <UserDisplay user={salesLog.createdBy} />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Payment:
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <UserDisplay user={salesLog.createdBy} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container flexWrap="nowrap">
            <Grid sx={{ width: 100, minWidth: 100 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Total:
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="body1" data-testid="sale-total">
                {displayPrice(salesLog.total)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={0}
          data-testid="sales-log-items"
          sx={{ mt: 1 }}
          xs={12}
          md={8}
        >
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Sale Items
            </Typography>
          </Grid>
          {salesLog.items.map((item, index) => (
            <ProductInstanceSummary
              key={index}
              productInstance={item}
              summaryComponents={
                adminView ? adminSummaryComponents : nonAdminSummaryComponents
              }
              hideOverflow={false}
            />
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
