import { Add } from "@mui/icons-material";
import { Button, Container, List, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import { Link } from "react-router-dom";
import { useAlerts } from "../../common/context/AlertContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import AdvancedRequestsBreadcrumbs from "./AdvancedRequestsBreadcrumbs";
import AdvancedRequestSummary from "./AdvancedRequestSummary";

export default function AdvancedRequests() {
  const { addErrorAlert } = useAlerts();
  const [upcomingRequests, setUpcomingRequests] = React.useState();
  const [pastRequests, setPastRequests] = React.useState();

  React.useEffect(() => {
    // fetch upcoming requests
    AdvancedRequestService.getUpcomingRequests()
      .then(setUpcomingRequests)
      .catch((err) => addErrorAlert("Error fetching upcoming requests", err));
    // fetch past requests
    AdvancedRequestService.getPastRequests()
      .then(setPastRequests)
      .catch((err) => addErrorAlert("Error fetching past requests", err));
  }, [addErrorAlert]);

  return (
    <Container sx={{ pt: 1.5 }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>
          <AdvancedRequestsBreadcrumbs />
        </Grid>
        <Grid>
          <Link to="/app/buyer/product-requests/new">
            <Button variant="contained" color="primary" startIcon={<Add />}>
              New Product Request
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Typography variant="h4" gutterBottom>
        Upcoming Requests
      </Typography>
      {upcomingRequests && Boolean(upcomingRequests.length === 0) && (
        <Typography variant="body1">No upcoming requests</Typography>
      )}
      {upcomingRequests && Boolean(upcomingRequests.length > 0) && (
        <List>
          {upcomingRequests.map((request) => (
            <AdvancedRequestSummary
              key={request.id}
              advancedRequest={request}
            />
          ))}
        </List>
      )}
      {!upcomingRequests && <Skeleton variant="rectangular" height={60} />}
      <Typography variant="h4" gutterBottom>
        Past Requests
      </Typography>
      {pastRequests && Boolean(pastRequests.length === 0) && (
        <Typography variant="body1">No upcoming requests</Typography>
      )}
      {pastRequests && Boolean(pastRequests.length > 0) && (
        <List dense>
          {pastRequests.map((request) => (
            <AdvancedRequestSummary
              key={request.id}
              advancedRequest={request}
            />
          ))}
        </List>
      )}
      {!pastRequests && <Skeleton variant="rectangular" height={40} />}
    </Container>
  );
}
