import { Container, Typography } from "@mui/material";
import { useAuthContext } from "../../common/context/AuthContext";
import { displayName } from "../../common/util/DisplayUtil";

const Dashboard = () => {
  const { currentUser } = useAuthContext();
  return (
    <Container>
      <Typography variant="h1" gutterBottom>
        Welcome, {displayName(currentUser.firstName, currentUser.lastName)}
      </Typography>
      <Typography variant="body1">
        This is the dashboard for the inventory system.
      </Typography>
    </Container>
  );
};

export default Dashboard;
