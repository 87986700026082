import { Box, ListItemButton, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay";
import UserDisplay from "../../../common/components/data-display/UserDisplay";
import { displayPrice } from "../../../common/util/DisplayUtil";

const SalesLogSummary = ({ item, ...props }) => {
  return (
    <ListItemButton
      sx={{
        mb: 0.25,
        p: 1,
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: 1,
        border: "1px solid #E0E0E0",
        gap: 1,
      }}
      LinkComponent={Link}
      to={
        `/app/business-admin/manage-sales-log/${item.id}` +
        (item.fulfillmentStatus === "DRAFT" ? "/edit" : "")
      }
      aria-label={`View invoice #${item.invoiceNumber}`}
      {...props}
      data-testid={"sales-log-summary"}
    >
      <Box
        sx={{
          display: "flex",
          minWidth: 0,
          ml: 2,
          mr: 0,
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">Invoice #</Typography>
          <Typography
            variante="body1"
            sx={{ fontWeight: 800, display: "inline-block" }}
            data-testid="invoice-number"
          >
            {item.invoiceNumber}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">Sale Date</Typography>
          <Typography
            variante="body1"
            sx={{ fontWeight: 800, display: "inline-block" }}
            data-testid="sale-date"
          >
            {moment(item.saleDate).format("ddd, M/D/YY")}
          </Typography>
        </Box>
        {
          <BusinessDisplay
            business={item.buyerBusiness}
            label="Buyer"
            sx={{ width: 220 }}
          />
        }
        <UserDisplay
          user={item.createdBy}
          label="Order Placed By"
          sx={{ width: 180 }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlgin: "end",
          }}
        >
          <Typography variant="caption" sx={{ alignSelf: "flex-end" }}>
            Total
          </Typography>
          <Typography
            variante="body1"
            sx={{
              alignSelf: "flex-end",
              fontWeight: 800,
              width: 120,
              display: "inline-block",
              textAlign: "end",
            }}
            data-testid="sale-total"
          >
            {displayPrice(item.total)}
          </Typography>
        </Box>
      </Box>
    </ListItemButton>
  );
};

export default SalesLogSummary;
