import { Chip } from "@mui/material";
import React from "react";

export default function ProductQuantityAvailableChip({ product }) {
  return (
    <Chip
      sx={{ ml: 1, width: "fit-content", textAlign: "end" }}
      data-testid="product-instance-quantity-available"
      size="small"
      label={`×${product.quantityAvailable}`}
      color={product.quantityAvailable === 0 ? "error" : "default"}
    />
  );
}
