export function displayName(first, last) {
  if (first && last) {
    return `${first} ${last}`;
  } else {
    return first || last || "";
  }
}

export function displayPrice(price) {
  if (price === undefined || price === null) {
    return "--";
  }
  return `$${price.toFixed(2)}`;
}

export function displayAdvancedRequestName(advancedRequest) {
  return advancedRequest?.id
    ? advancedRequest.orderReference || `AO${advancedRequest.id}`
    : "New Request";
}
