import { Yard } from "@mui/icons-material";
import { Avatar, Box, Card, CardActionArea, Typography } from "@mui/material";
import React from "react";
import TypographyEllipsis from "../data-display/TypographyEllipsis";
import { PICKLINQ_COLOR_MAP } from "../form/FormFlowerColorPicker";

export const getInstanceAttributesText = (
  attributes,
  includeAttributeName = false
) => {
  // Sort attributes by AttributeName. Quality comes first, then PrimaryColor, then SecondaryColor
  attributes?.sort((a, b) => {
    if (a.attributeName === "PrimaryColor") {
      return -1;
    } else if (b.attributeName === "PrimaryColor") {
      return 1;
    } else if (a.attributeName === "SecondaryColor") {
      return -1;
    } else if (b.attributeName === "SecondaryColor") {
      return 1;
    } else if (a.attributeName === "Quality") {
      return -1;
    } else if (b.attributeName === "Quality") {
      return 1;
    } else {
      return 0;
    }
  });

  const attributeDisplays = attributes?.map((attr, idx) => {
    if (
      attr.attributeName === "PrimaryColor" ||
      attr.attributeName === "SecondaryColor"
    ) {
      return (
        <span key={idx}>
          {PICKLINQ_COLOR_MAP[attr.attributeValue] && (
            <span
              style={{
                height: 12,
                width: 12,
                backgroundColor:
                  PICKLINQ_COLOR_MAP[attr.attributeValue].hexValue,
                display: "inline-block",
                marginRight: 1,
                borderRadius: "50%",
                border: `1px solid ${PICKLINQ_COLOR_MAP[attr.attributeValue].textHexValue}`,
              }}
            />
          )}
          {includeAttributeName ? `${attr.attributeName}: ` : ""}{" "}
          {attr.attributeValue}
        </span>
      );
    } else {
      return (
        <span key={idx}>
          {includeAttributeName ? `${attr.attributeName}: ` : ""}
          {attr.attributeValue}
        </span>
      );
    }
  });
  return attributeDisplays?.reduce((prev, curr) => [prev, ", ", curr]);
};

export function ProductInstanceSummary({
  item,
  productInstance,
  summaryComponents = [],
  onClick,
  sx,
  hideOverflow = false,
  ...props
}) {
  const product = productInstance || item;
  const BaseComponent = onClick ? CardActionArea : Box;
  const TextComponent = hideOverflow ? TypographyEllipsis : Typography;
  return (
    <Card
      sx={{
        mb: 0.125,
        width: "100%",
        maxWidth: 600,
        textAlign: "start",
        ...sx,
      }}
      data-testid={"product-instance-summary"}
      {...props}
    >
      {/* If onClick prop is provided, use a CardActionArea - else use a Box */}
      <BaseComponent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 0.5,
          flexWrap: hideOverflow ? "nowrap" : "wrap",
        }}
        onClick={onClick}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Avatar
            src={product.productPicturePath}
            alt={product.name}
            sx={{ height: 40, width: 40 }}
          >
            <Yard />
          </Avatar>
          <Box
            sx={{
              ml: 1.5,
              flexGrow: 1,
              maxWidth: 220,
              textWrap: "wrap",
            }}
          >
            <TextComponent variant="body1" sx={{ fontWeight: 800 }}>
              {product.name}
            </TextComponent>
            <TextComponent variant="body2">
              {getInstanceAttributesText(product.attributes)}
            </TextComponent>
          </Box>
        </Box>
        {summaryComponents.map((SummaryComponent, idx) => (
          <SummaryComponent key={idx} product={product} />
        ))}
      </BaseComponent>
    </Card>
  );
}
