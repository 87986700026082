import {
  Logout,
  ManageAccounts,
  Person,
  ShoppingBag,
  Yard,
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Badge, Menu, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { Link, Outlet } from "react-router-dom";
import MainAppBar from "../common/components/MainAppBar";
import UserAvatar from "../common/components/UserAvatar";
import { useAuthContext } from "../common/context/AuthContext";
import { displayName } from "../common/util/DisplayUtil";

export const NAV_DRAWER_WIDTH = 240;
const openedMixin = (theme) => ({
  width: NAV_DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export function ProfileMenu({ drawerOpen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { currentUser, logOut, isMasquerading, masqueradeLogout } =
    useAuthContext();

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function onClose() {
    setAnchorEl(null);
  }

  return (
    <List>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          id="profile-menu-button"
          sx={{
            minHeight: 48,
            justifyContent: drawerOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={handleClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {
              <Badge
                badgeContent="!"
                color="error"
                invisible={!isMasquerading}
                id="masquerade-warning-icon"
              >
                <UserAvatar id="current-user-avatar" user={currentUser} />
              </Badge>
            }
          </ListItemIcon>
          <ListItemText
            primary={"My Account"}
            sx={{ opacity: drawerOpen ? 1 : 0 }}
          />
        </ListItemButton>
        <Menu
          id="profile-menu"
          MenuListProps={{
            "aria-labelledby": "profile-menu-btn-",
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <ListItem id="menu-active-user-name">
            {displayName(currentUser.firstName, currentUser.lastName)}
          </ListItem>
          <MenuItem
            component={Link}
            to={`/app/business-admin/manage-users/${currentUser.id}`}
            onClick={() => setAnchorEl(null)}
          >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
          {isMasquerading && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                masqueradeLogout();
              }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText
                primary="Stop Masquerading"
                secondary={`Back to ${displayName(currentUser.masqueradeUser.firstName, currentUser.masqueradeUser.lastName)}`}
              />
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              logOut();
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </MenuItem>
        </Menu>
      </ListItem>
    </List>
  );
}

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: NAV_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function UserHome() {
  // eslint-disable-next-line no-unused-vars
  const { isMemberOfCollective, isMemberOfAnyBuyer, isMemberOfAnySupplier } =
    useAuthContext();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <MainAppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <Drawer variant="permanent" open={open} style={{ height: "100%" }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            {
              linkTo: "/app/buyer",
              displayText: "Buyer Home",
              icon: <ShoppingBag />,
              canView: isMemberOfAnyBuyer,
            },
            {
              linkTo: "/app/supplier",
              displayText: "Supplier Home",
              icon: <Yard />,
              canView: isMemberOfAnySupplier,
            },
            {
              linkTo: "/app/business-admin",
              displayText: "Administration",
              icon: <ManageAccounts />,
              canView: isMemberOfCollective,
            },
          ]
            .filter((item) => item.canView)
            .map((navItem) => (
              <ListItem
                key={navItem.displayText}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  LinkComponent={Link}
                  to={navItem.linkTo}
                  aria-label={navItem.displayText}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {navItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={navItem.displayText}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <ProfileMenu drawerOpen={open} />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
          height: "calc(100% - 64px)",
          mt: { xs: 7, sm: 8 },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
