import { Yard } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { getInstanceAttributesText } from "../../../common/components/product/ProductInstanceSummary";
import ProductQuantityAvailableChip from "../../../common/components/product/ProductQuantityAvailableChip";
import { displayPrice } from "../../../common/util/DisplayUtil";
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function PresaleInventoryProductSummary({
  item,
  onClick,
  ...props
}) {
  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: 230,
        textAlign: "center",
        alignItems: "start",
        justifyContent: "start",
      }}
      data-testid="inventory-item-summary"
      {...props}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          height: "100%",
          width: "100%",
          justifyContent: "start",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {item.productPicturePath && (
          <CardMedia
            sx={{ height: 160, width: "100%" }}
            image={item.productPicturePath || <Yard />}
            title={item.name}
          />
        )}
        {!item.productPicturePath && (
          <Yard
            sx={{
              height: 160,
              width: 160,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        )}
        {/* Overlay an "out of stock" message when item.quantityAvailable is 0 */}
        {item.quantityAvailable === 0 && (
          <Typography
            variant="h6"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -100%)",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              padding: 1,
              borderRadius: 1,
            }}
            color="#FFF"
          >
            Out of Stock
          </Typography>
        )}
        <Grid container sx={{ p: 0.5 }} spacing={0.5}>
          <Grid
            item
            container
            spacing={1}
            justifyContent="space-between"
            flexWrap="nowrap"
          >
            <Grid item>
              <Typography
                variant="body1"
                sx={{
                  textWrap: "wrap",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                {item.name}
              </Typography>
            </Grid>
            <Grid item flexShrink={0}>
              <ProductQuantityAvailableChip product={item} />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={1}
            justifyContent="space-between"
            textAlign="start"
            flexWrap={"nowrap"}
          >
            <Grid item>{getInstanceAttributesText(item.attributes)}</Grid>
            <Grid item flexShrink={0}>
              <Typography variant="body1">
                {displayPrice(item.estimatedUnitPrice)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
