import { InputAdornment } from "@mui/material";
import { FormTextField } from "./FormTextField";

export function FormCurrencyField(props) {
  return (
    <FormTextField
      {...props}
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
}
