import { Check, PhotoCamera, Save } from "@mui/icons-material";
import {
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Container,
  Fab,
  List,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2.js";
import { FastField, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormTextField } from "../../../common/components/form/FormTextField.jsx";
import { useAlerts } from "../../../common/context/AlertContext.jsx";
import { useAuthContext } from "../../../common/context/AuthContext.jsx";
import BusinessService from "../../../common/service/BusinessService.js";
import UserService from "../../../common/service/UserService.js";
import { displayName } from "../../../common/util/DisplayUtil.js";
import BusinessUserSummary from "../ManageBusinesses/BusinessUserSummary.jsx";

const defaultUserInfo = {
  firstName: "",
  lastName: "",
  email: "",
  accountStatus: "",
};

const EditUser = () => {
  const [loading, setLoading] = useState(true);
  const [loadingProfilePic, setLoadingProfilePic] = useState(false);
  const navigate = useNavigate();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const formikRef = React.useRef();
  const [businesses, setBusinesses] = useState();
  const [loadingBusinesses, setLoadingBusinesses] = useState(true);
  const { currentUser, isOwnerOfCollective, masqueradeAsUser, isMasquerading } =
    useAuthContext();
  // Coerce user id to be numeric if it is a number
  let { userId } = useParams();
  userId = isNaN(userId) ? userId : parseInt(userId);
  useEffect(() => {
    if (!userId) {
      return;
    } else if (userId === "new") {
      setUserInfo(defaultUserInfo);
      setBusinesses([]);
      setLoading(false);
    } else {
      fetchUserInfo(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  async function fetchUserInfo(userId) {
    setLoading(true);
    setLoadingBusinesses(true);
    try {
      const response = await UserService.getUserById(userId);
      setUserInfo(response);
      setLoading(false);
      formikRef.current.setValues({ ...response });
    } catch (error) {
      addErrorAlert("Error fetching user", error);
    }
    try {
      const response = await BusinessService.getBusinessesByUserId(userId);
      setBusinesses(response);
      setLoadingBusinesses(false);
    } catch (error) {
      addErrorAlert("Error fetching user's businesses", error);
    }
  }

  const [userInfo, setUserInfo] = useState(defaultUserInfo);

  const handleSubmit = async (userInfoToSave) => {
    if (userId === "new") {
      return UserService.createUser(userInfoToSave).then((createdUser) => {
        navigate(`/app/business-admin/manage-users/${createdUser.id}`, {
          replace: true,
        });
        formikRef.current.resetForm({ values: createdUser });
        addSuccessAlert("User created");
      });
    } else {
      return UserService.updateUser(userId, userInfoToSave).then(
        (updatedUser) => {
          formikRef.current.resetForm({ values: updatedUser });
          addSuccessAlert("Changes saved");
        }
      );
    }
  };

  const emailVerified = userInfo?.emailVerified;

  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h1" gutterBottom>
        {loading ? (
          <Skeleton />
        ) : userId === "new" ? (
          "Add New User"
        ) : (
          "Edit User"
        )}
      </Typography>
      <Formik
        initialValues={userInfo}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required("Required"),
          email: Yup.string().email("Invalid email").required("Required"),
        })}
      >
        {(props) => (
          <Form>
            {loading ? (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
              </>
            ) : (
              <>
                <Grid container spacing={4}>
                  <Grid container spacing={2} xs={12} md={7}>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Typography variant="h4">Contact Info</Typography>
                      </Grid>
                      <Grid xs={12} sm={6} md={4}>
                        <FastField
                          as={FormTextField}
                          label="First Name"
                          name="firstName"
                        />
                      </Grid>
                      <Grid xs={12} sm={6} md={4}>
                        <FastField
                          as={FormTextField}
                          label="Last Name"
                          name="lastName"
                        />
                      </Grid>
                      <Grid xs={12} md={6}>
                        <FastField
                          as={FormTextField}
                          label="User Email"
                          name="email"
                          type="email"
                          required
                          InputProps={{
                            readOnly: Boolean(userInfo.emailVerified),
                            endAdornment: userInfo.emailVerified && (
                              <Tooltip title="Email is verified">
                                <Check size={16} />
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} xs={12} md={5}>
                    <Grid xs={12} container>
                      <Grid>
                        <Typography variant="h4">
                          Account Info {userId !== "new" && `- ID: ${userId}`}
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Card
                          sx={{
                            width: 150,
                            height: 150,
                            position: "relative",
                          }}
                        >
                          {userInfo.profilePicPath && (
                            <CardMedia
                              id="profile-pic-display"
                              component="img"
                              image={userInfo.profilePicPath}
                              sx={{
                                height: "100%",
                                width: "100%",
                              }}
                            />
                          )}
                          {Boolean(
                            currentUser.id === userId || !emailVerified
                          ) && (
                            <Tooltip
                              title="Select New Profile Picture"
                              placement="top"
                            >
                              <Fab
                                id="change-profile-pic-button"
                                sx={{
                                  position: "absolute",
                                  right: 4,
                                  bottom: 4,
                                }}
                                size="small"
                                onClick={() =>
                                  document
                                    .getElementById("change-profile-pic-file")
                                    .click()
                                }
                                disabled={loadingProfilePic}
                              >
                                {loadingProfilePic ? (
                                  <CircularProgress size="small" />
                                ) : (
                                  <PhotoCamera />
                                )}
                              </Fab>
                            </Tooltip>
                          )}
                          <input
                            id={"change-profile-pic-file"}
                            type="file"
                            accept="image"
                            value=""
                            hidden
                            onChange={async (e) => {
                              setLoadingProfilePic(true);
                              try {
                                const updatedUser =
                                  await UserService.updateProfilePic(
                                    userId,
                                    e.target.files[0]
                                  );
                                setUserInfo({
                                  ...userInfo,
                                  profilePicPath: updatedUser.profilePicPath,
                                });
                                addSuccessAlert("Profile picture updated");
                              } catch (error) {
                                addErrorAlert(
                                  "Error uploading profile pic",
                                  error
                                );
                              } finally {
                                setLoadingProfilePic(false);
                              }
                            }}
                          />
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} xs={12}>
                    <Grid xs={12}>
                      <Typography variant="h4">Businesses</Typography>
                      {loadingBusinesses && <Skeleton variant="rectangular" />}
                      {businesses?.length === 0 && (
                        <Typography>No businesses found</Typography>
                      )}
                      {businesses?.length > 0 && (
                        <List sx={{ width: "100%" }}>
                          {businesses.map((businessUser) => (
                            <BusinessUserSummary
                              businessUser={businessUser}
                              showBusinessInfo
                              key={businessUser.businessId}
                            />
                          ))}
                        </List>
                      )}
                    </Grid>
                    <Grid xs={12}>
                      <Typography variant="body1">
                        Created by{" "}
                        {displayName(
                          userInfo.createdByFirstName,
                          userInfo.createdByLastName
                        )}{" "}
                        on {moment(userInfo.createdAt).format("LLLL")}
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography variant="body1">
                        Updated by{" "}
                        {displayName(
                          userInfo.updatedByFirstName,
                          userInfo.updatedByLastName
                        )}{" "}
                        on {moment(userInfo.updatedAt).format("LLLL")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="primary"
                  type="button"
                  startIcon={<Save />}
                  disabled={props.isSubmitting}
                  onClick={props.submitForm}
                >
                  Save
                </Button>
              </>
            )}
          </Form>
        )}
      </Formik>

      {userId !== "new" &&
        !isMasquerading &&
        isOwnerOfCollective &&
        currentUser.id !== userId && (
          <Button
            onClick={() =>
              masqueradeAsUser(userId).catch((e) =>
                addErrorAlert("There was an error logging in as this user", e)
              )
            }
          >
            Log in as User
          </Button>
        )}
    </Container>
  );
};

export default EditUser;
