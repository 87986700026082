import axios from "axios";

export const AGED_OUT_STOCK_BASE_URL =
  process.env.REACT_APP_API_PATH + "/agedOutStock";

const AgedOutService = {
  async searchAgedOutStock(searchCriteria) {
    const response = await axios.post(
      AGED_OUT_STOCK_BASE_URL + "/search",
      searchCriteria
    );
    return response.data;
  },
  async createAgedOutStock(newStock) {
    const response = await axios.post(AGED_OUT_STOCK_BASE_URL, newStock);
    return response.data;
  },
  async updateAgedOutStock(stockId, updatedStock) {
    const response = await axios.put(
      AGED_OUT_STOCK_BASE_URL + "/" + stockId,
      updatedStock
    );
    return response.data;
  },
  async deleteAgedOutStock(stockId) {
    const response = await axios.delete(
      AGED_OUT_STOCK_BASE_URL + "/" + stockId
    );
    return response.data;
  },
};

export default AgedOutService;
