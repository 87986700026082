import React, { createContext, useContext, useState } from "react";
import { useAppTheme } from "../../AppTheme";
import ApplicationLoading from "../components/ApplicationLoading";
import CollectiveService from "../service/CollectiveService";
import { useAlerts } from "./AlertContext";

const CollectiveContext = createContext();

export const CollectiveProvider = ({ children }) => {
  const { addErrorAlert } = useAlerts();
  const [collectiveInfo, setCollectiveInfo] = useState(null);
  const { setPrimaryColor, setSecondaryColor } = useAppTheme();

  React.useEffect(() => {
    CollectiveService.getCurrentCollectiveInfo()
      .then((info) => {
        setCollectiveInfo(info);
        setPrimaryColor(info.primaryColor);
        setSecondaryColor(info.secondaryColor);
      })
      .catch((e) => addErrorAlert("Failed to load collective info", e));
  }, [addErrorAlert, setPrimaryColor, setSecondaryColor]);

  return (
    <CollectiveContext.Provider value={{ collectiveInfo }}>
      {collectiveInfo ? children : <ApplicationLoading />}
    </CollectiveContext.Provider>
  );
};

export const useCollective = () => useContext(CollectiveContext);
