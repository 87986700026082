import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import { ErrorMessage } from "formik";
import React, { useState } from "react";

export const PICKLINQ_FLOWER_COLORS = [
  {
    name: "Blue",
    hexValue: "#0112BB",
    textHexValue: "#FFF",
    colorOptions: [
      { hexValue: "#0112BB", name: "Bright" },
      { hexValue: "#1B2761", name: "Dark" },
      { hexValue: "#3366AB", name: "Muted" },
      { hexValue: "#7AC4F8", name: "Pale" },
    ],
  },
  {
    name: "Red",
    hexValue: "#FF0000",
    textHexValue: "#FFF",
    colorOptions: [
      { hexValue: "#FF0000", name: "Bright" },
      { hexValue: "#590606", name: "Dark" },
      { hexValue: "#C54242", name: "Muted" },
      { hexValue: "#F55853", name: "Pale" },
    ],
  },
  {
    name: "Yellow",
    hexValue: "#FFDD00",
    textHexValue: "#000",
    colorOptions: [
      { hexValue: "#FFDD00", name: "Bright", textHexValue: "#000" },
      { hexValue: "#BAA000", name: "Dark", textHexValue: "#fff" },
      { hexValue: "#DAC74B", name: "Muted", textHexValue: "#000" },
      { hexValue: "#FEE383", name: "Pale", textHexValue: "#000" },
    ],
  },
  {
    name: "Green",
    hexValue: "#5DD800",
    textHexValue: "#000",
    colorOptions: [
      { hexValue: "#5DD800", name: "Bright", textHexValue: "#000" },
      { hexValue: "#245201", name: "Dark", textHexValue: "#fff" },
      { hexValue: "#598737", name: "Muted", textHexValue: "#000" },
      { hexValue: "#CFF0A4", name: "Pale", textHexValue: "#000" },
    ],
  },
  {
    name: "Violet",
    hexValue: "#9500DB",
    textHexValue: "#FFF",
    colorOptions: [
      { hexValue: "#9500DB", name: "Bright", textHexValue: "#000" },
      { hexValue: "#491B61", name: "Dark", textHexValue: "#fff" },
      { hexValue: "#9371A6", name: "Muted", textHexValue: "#000" },
      { hexValue: "#CA78FC", name: "Pale", textHexValue: "#000" },
    ],
  },
  {
    name: "Pink",
    hexValue: "#FF00F0",
    textHexValue: "#000",
    colorOptions: [
      { hexValue: "#FF00F0", name: "Bright", textHexValue: "#000" },
      { hexValue: "#9C036F", name: "Dark", textHexValue: "#fff" },
      { hexValue: "#D068B1", name: "Muted", textHexValue: "#000" },
      { hexValue: "#FAB5E6", name: "Pale", textHexValue: "#000" },
    ],
  },
  {
    name: "Brown",
    hexValue: "#814C19",
    textHexValue: "#FFF",
    colorOptions: [
      { hexValue: "#814C19", name: "Bright", textHexValue: "#000" },
      { hexValue: "#482606", name: "Dark", textHexValue: "#fff" },
      { hexValue: "#816448", name: "Muted", textHexValue: "#000" },
      { hexValue: "#C4A07E", name: "Pale", textHexValue: "#000" },
    ],
  },
  {
    name: "Orange",
    hexValue: "#FF5500",
    textHexValue: "#000",
    colorOptions: [
      { hexValue: "#FF5500", name: "Bright", textHexValue: "#000" },
      { hexValue: "#C64500", name: "Dark", textHexValue: "#fff" },
      { hexValue: "#D77744", name: "Muted", textHexValue: "#000" },
      { hexValue: "#faa072", name: "Pale", textHexValue: "#000" },
    ],
  },
  {
    name: "White",
    hexValue: "#ffffff",
    textHexValue: "#000",
    colorOptions: [
      { hexValue: "#ffffff", name: "Bright", textHexValue: "#000" },
      { hexValue: "#E2E0D0", name: "Dark", textHexValue: "#000" },
      { hexValue: "#FFFDE8", name: "Muted", textHexValue: "#000" },
    ],
  },
];

const flatColorOptions = PICKLINQ_FLOWER_COLORS.reduce((acc, color) => {
  const { name, hexValue, textHexValue } = color;
  const colorOptions = color.colorOptions.map((option) => {
    return {
      name: `${name} (${option.name})`,
      hexValue: option.hexValue,
      textHexValue: option.textHexValue,
    };
  });
  return [...acc, { name, hexValue, textHexValue }, ...colorOptions];
}, []);

export const PICKLINQ_COLOR_MAP = flatColorOptions.reduce((acc, color) => {
  acc[color.name] = {
    name: color.name,
    hexValue: color.hexValue,
    textHexValue: color.textHexValue,
  };
  return acc;
}, {});

function getColorOptionFromName(colorName) {
  if (!colorName) {
    return "";
  }
  return (
    flatColorOptions.find((color) => color.name === colorName) || {
      name: colorName,
      hexValue: "#FFF",
      textHexValue: "#000",
    }
  );
}

export default function FormFlowerColorPicker({ ...props }) {
  const [mainColor, setMainColor] = useState("");
  const [colorList, setColorList] = useState([...PICKLINQ_FLOWER_COLORS]);
  React.useEffect(() => {
    if (props.value) {
      const newVal = getColorOptionFromName(props.value);
      setMainColor(newVal?.name);
      setColorList([newVal, ...PICKLINQ_FLOWER_COLORS]);
    }
  }, [props.value]);

  const handleMainColorChange = (event) => {
    setMainColor(event.target.value);
    if (event.target.value) {
      setAnchorEl(selectRef.current);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const selectedColor = PICKLINQ_FLOWER_COLORS.find(
    (color) => color.name === mainColor
  );
  const selectRef = React.useRef(null);

  function selectShade(shade) {
    const curColor = {
      hexValue: shade.hexValue,
      textHexValue: shade.textHex,
      name: `${mainColor} (${shade.name})`,
    };
    setColorList([curColor, ...PICKLINQ_FLOWER_COLORS]);
    props.onChange(
      {
        target: {
          name: props.name,
          value: curColor.name,
        },
      },
      curColor.name
    );
    setMainColor(curColor.name);
    setAnchorEl(null);
  }

  return (
    <Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <FormControl sx={{ minWidth: 240 }}>
          <InputLabel id={props.name + "-label"} size="small">
            {props.label}
          </InputLabel>
          <Select
            labelId={props.name + "-label"}
            id={props.name}
            data-testid={props.name}
            label={props.label}
            fullWidth
            {...props}
            value={mainColor}
            onChange={handleMainColorChange}
            ref={selectRef}
            size="small"
          >
            <MenuItem value={""}>Select a color</MenuItem>
            {colorList.map((color) => (
              <MenuItem
                key={color.name}
                value={color.name}
                onClick={(e) => {
                  setAnchorEl(null);
                  return false;
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      border: "1px solid #AAA",
                      backgroundColor: color.hexValue,
                      marginRight: 1,
                    }}
                  />
                  {color.name}
                </Box>
              </MenuItem>
            ))}
          </Select>

          {
            // Don't show the error message while the popover is open
            !anchorEl && (
              <ErrorMessage name={props.name}>
                {(msg) => <FormHelperText error>{msg}</FormHelperText>}
              </ErrorMessage>
            )
          }
        </FormControl>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={(e, reason) => {
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
              const defaultShade = selectedColor.colorOptions[0];
              selectShade(defaultShade);
            }
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            {selectedColor &&
              selectedColor.colorOptions.map((option) => (
                <Button
                  key={option.name}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 0.5,
                    width: "100%",
                  }}
                  onClick={() => {
                    selectShade(option);
                  }}
                  data-testid={option.name}
                >
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      border: "1px solid #AAA",
                      backgroundColor: option.hexValue,
                      marginRight: 1,
                    }}
                  />
                  <Typography>{option.name}</Typography>
                </Button>
              ))}
          </Typography>
        </Popover>
      </Box>
    </Box>
  );
}
