import { Paper } from "@mui/material";
import { Field } from "formik";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";

export default function ManageAgedOutStockSearchCriteria() {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
      }}
      elevation={0}
    >
      <Field
        as={DebouncedTextField}
        label="Search..."
        name="searchText"
        sx={{ maxWidth: 200, width: "100%" }}
      />
      <Field
        as={BusinessAutocomplete}
        name="businesses"
        label="Business"
        suppliersOnly
        multiple
        sx={{ maxWidth: 200, width: "100%" }}
      />
      <div>
        <Field
          as={FormDatePicker}
          name="dateStockedStart"
          label="Stocked date start"
          sx={{ maxWidth: 180, width: "100%" }}
          size="small"
        />
      </div>
      <div>
        <Field
          as={FormDatePicker}
          name="dateStockedEnd"
          label="Stocked date end"
          sx={{ maxWidth: 180, width: "100%" }}
          size="small"
        />
      </div>
    </Paper>
  );
}
