import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const LoadingOverlay = () => {
  return (
    <Box
      data-testid="loading-overlay"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        zIndex: 1200,
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default LoadingOverlay;
