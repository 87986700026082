import { Container, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import PresaleOrderService from "../../../common/service/PresaleOrderService";
import { AdminPresaleOrderSummary } from "../../Buyer/Presale/PresaleOrderSummary";
import ManagePresaleOrderSearchCriteria from "./ManagePresaleOrdersSearchCriteria";

export default function ManagePresaleOrders() {
  const locationState = useLocation().state;
  const lastSearchedCriteria = locationState?.lastSearchedCriteria;
  const defaultSearchCriteria = lastSearchedCriteria || {
    searchText: "",
    page: 1,
    itemsPerPage: 10,
    startDateRange: null,
    endDateRange: null,
    buyerBusinesses: [],
  };
  return (
    <Container data-testid="manage-presale-orders-page">
      <Typography variant="h1">Presale Orders</Typography>
      <PaginatedSearchResults
        fetchSearchResults={PresaleOrderService.getPresaleOrders}
        defaultSearchCriteria={defaultSearchCriteria}
        SearchCriteriaComponent={ManagePresaleOrderSearchCriteria}
        data-testid="paginated-search-results"
        ResultDisplayComponent={AdminPresaleOrderSummary}
        validationSchema={Yup.object().shape({
          searchText: Yup.string(),
          // Ensure startDateRange is before endDateRange
          startDateRange: Yup.date()
            .nullable()
            .typeError("Invalid Date")
            // Only allow dates after 2000
            .min("2000-01-01")
            .test(
              "before-end-date-range",
              "Start date must be before end date",
              function (value) {
                const endDate = this.parent.endDateRange;
                if (!value || !endDate) {
                  return true;
                }
                return value.isBefore(endDate);
              }
            ),
          endDateRange: Yup.date()
            .nullable()
            .typeError("Invalid Date")
            .min("2000-01-01"),
          buyerBusinesses: Yup.array(),
        })}
        normalizeSearchCriteria={(criteria) => {
          // Remove any values that are falsey
          const cleanedCriteria = Object.fromEntries(
            Object.entries(criteria).filter(([_, v]) => v)
          );
          if (
            cleanedCriteria.startDateRange &&
            typeof cleanedCriteria.startDateRange !== "string"
          ) {
            cleanedCriteria.startDateRange =
              cleanedCriteria.startDateRange.format("YYYY-MM-DD");
          }
          if (
            cleanedCriteria.endDateRange &&
            typeof cleanedCriteria.endDateRange !== "string"
          ) {
            cleanedCriteria.endDateRange =
              cleanedCriteria.endDateRange.format("YYYY-MM-DD");
          }
          return cleanedCriteria;
        }}
      />
    </Container>
  );
}
