import { Container, Typography } from "@mui/material";
import React from "react";

export default function PageNotFound() {
  return (
    <Container sx={{ marginTop: "20vh" }}>
      <Typography variant="h1" align="center">
        We couldn't find the page you're looking for!
      </Typography>
    </Container>
  );
}
