import React from "react";
import FormCheckboxWithLabel from "./FormCheckboxWithLabel";
import FormFlowerColorPicker from "./FormFlowerColorPicker";
import FormSelect from "./FormSelect";
import { FormTextField } from "./FormTextField";

export function getOptionsForAttribute(attribute) {
  switch (attribute.attributeName) {
    case "Quality":
      return [
        {
          value: "Basic",
          label: "Basic",
        },
        {
          value: "Standard",
          label: "Standard",
        },
        {
          value: "Premium",
          label: "Premium",
        },
      ];
    case "String":
      return null;
    case "Boolean":
      return ["true", "false"];
    default:
      throw new Error(`Unsupported attribute type: ${attribute.attributeType}`);
  }
}

export default function AttributeFormField({ attribute, ...props }) {
  switch (attribute.attributeType) {
    case "Options":
      return (
        <FormSelect
          label={attribute.attributeName}
          {...props}
          options={getOptionsForAttribute(attribute)}
        />
      );
    case "String":
      if (
        attribute.attributeName === "PrimaryColor" ||
        attribute.attributeName === "SecondaryColor"
      ) {
        return (
          <FormFlowerColorPicker label={attribute.attributeName} {...props} />
        );
      }
      return <FormTextField label={attribute.attributeName} {...props} />;
    case "Boolean":
      return (
        <FormCheckboxWithLabel
          label={attribute.attributeName}
          {...props}
          onChange={(e, v) => {
            // Checkboxes are a little funky - need to handle their onChange differently
            props.onChange({
              target: {
                name: props.name,
                value: v,
              },
            });
          }}
        />
      );
    default:
      throw new Error(`Unsupported attribute type: ${attribute.attributeType}`);
  }
}
