import { Add } from "@mui/icons-material";
import { Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults.jsx";
import BaseProductService from "../../../common/service/BaseProductService.js";
import BaseProductSearchCriteria from "./BaseProductSearchCriteria.jsx";
import BaseProductSummary from "./BaseProductSummary.jsx";

const ManageBaseProducts = () => {
  const locationState = useLocation().state;
  const lastSearchedCriteria = locationState?.lastSearchedCriteria;
  const defaultSearchCriteria = lastSearchedCriteria || {
    searchText: "",
    activeStatus: "active",
    page: 1,
    itemsPerPage: 10,
  };
  return (
    <Container sx={{ pt: 1.5 }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h2" gutterBottom>
            Manage Base Products
          </Typography>
        </Grid>
        <Grid item>
          <Link to="/app/business-admin/manage-base-products/new">
            <Button variant="contained" color="primary" startIcon={<Add />}>
              Add Base Product
            </Button>
          </Link>
        </Grid>
      </Grid>
      <PaginatedSearchResults
        fetchSearchResults={BaseProductService.getBaseProducts}
        ResultDisplayComponent={BaseProductSummary}
        SearchCriteriaComponent={BaseProductSearchCriteria}
        defaultSearchCriteria={defaultSearchCriteria}
      />
    </Container>
  );
};

export default ManageBaseProducts;
