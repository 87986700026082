import { Avatar } from "@mui/material";
import { displayName } from "../util/DisplayUtil";

export default function UserAvatar({ user, ...props }) {
  if (!user) {
    return <Avatar {...props} />;
  }
  if (!user.profilePicPath) {
    return (
      <Avatar alt={displayName(user.firstName, user.lastName)} {...props} />
    );
  }

  return (
    <Avatar
      alt={displayName(user.firstName, user.lastName)}
      src={user.profilePicPath}
      {...props}
    />
  );
}
