import axios from "axios";

export const ADVANCED_REQ_BASE_URL =
  process.env.REACT_APP_API_PATH + "/advancedRequests";

const AdvancedRequestService = {
  async getUpcomingRequests() {
    const response = await axios.get(ADVANCED_REQ_BASE_URL + "/upcoming");
    return response.data;
  },
  async getPastRequests() {
    const response = await axios.get(ADVANCED_REQ_BASE_URL + "/past");
    return response.data;
  },
  async getAdvancedRequests(searchCriteria) {
    const response = await axios.post(
      ADVANCED_REQ_BASE_URL + "/search",
      searchCriteria
    );
    return response.data;
  },
  async submitAdvancedRequest(advancedRequestId) {
    const response = await axios.put(
      `${ADVANCED_REQ_BASE_URL}/${advancedRequestId}/submit`
    );
    return response.data;
  },
  async getProductsForUpcomingRequests(searchCriteria) {
    const response = await axios.post(
      ADVANCED_REQ_BASE_URL + "/products/upcoming",
      searchCriteria
    );
    return response.data;
  },
  async getFulfillmentForProduct(advancedRequestProductId) {
    const response = await axios.get(
      ADVANCED_REQ_BASE_URL +
        "/product/" +
        advancedRequestProductId +
        "/fulfillment"
    );
    return response.data;
  },
  async addProductFulfillment(productFulfillment) {
    const response = await axios.post(
      ADVANCED_REQ_BASE_URL +
        "/product/" +
        productFulfillment.advancedRequestProductId +
        "/fulfill",
      productFulfillment
    );
    return response.data;
  },
  async updateProductAvailabilityStatus(
    advancedRequestId,
    productId,
    availabilityStatus
  ) {
    const response = await axios.put(
      ADVANCED_REQ_BASE_URL +
        "/" +
        advancedRequestId +
        "/requestProduct/" +
        productId +
        "/availabilityStatus",
      { availabilityStatus }
    );
    return response.data;
  },
  async updateProductFulfillment(
    advancedRequestProductId,
    productFulfillments
  ) {
    const response = await axios.put(
      ADVANCED_REQ_BASE_URL + `/product/${advancedRequestProductId}/fulfill`,
      productFulfillments
    );
    return response.data;
  },
  async deleteProductFulfillment(productFulfillmentId, supplierId) {
    const response = await axios.delete(
      ADVANCED_REQ_BASE_URL +
        "/product/fulfillment/" +
        productFulfillmentId +
        "/supplier/" +
        supplierId
    );
    return response.data;
  },
  async getAdvancedRequestById(advancedRequestId) {
    const response = await axios.get(
      ADVANCED_REQ_BASE_URL + "/" + advancedRequestId
    );
    return response.data;
  },
  async createAdvancedRequest(advancedRequest) {
    const response = await axios.post(ADVANCED_REQ_BASE_URL, advancedRequest);
    return response.data;
  },
  async updateAdvancedRequest(advancedRequestId, advancedRequest) {
    const response = await axios.put(
      ADVANCED_REQ_BASE_URL + "/" + advancedRequestId,
      advancedRequest
    );
    return response.data;
  },
  async addReferenceImage(advancedRequestId, referenceImageFile) {
    const file = referenceImageFile;
    const formData = new FormData();

    formData.append("file", file);
    const response = await axios.post(
      ADVANCED_REQ_BASE_URL + "/" + advancedRequestId + "/referenceImage",
      formData
    );
    return response.data;
  },
  async addProductToRequest(advancedRequestId, product) {
    const response = await axios.post(
      ADVANCED_REQ_BASE_URL + "/" + advancedRequestId + "/requestProduct",
      product
    );
    return response.data;
  },
  async updateProductInRequest(advancedRequestId, product) {
    const response = await axios.put(
      ADVANCED_REQ_BASE_URL + "/" + advancedRequestId + "/requestProduct",
      product
    );
    return response.data;
  },
  async deleteProductFromRequest(advancedRequestId, productId) {
    const response = await axios.delete(
      ADVANCED_REQ_BASE_URL +
        "/" +
        advancedRequestId +
        "/requestProduct/" +
        productId
    );
    return response.data;
  },
};

export default AdvancedRequestService;
