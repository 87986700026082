import React, { useState } from "react";
import { FormTextField } from "./FormTextField";

const DebouncedTextField = ({ debounceMs = 300, setLoading, ...props }) => {
  const [displayValue, setDisplayValue] = useState(props.value);
  const [debounceTimeout, setDebounceTimeout] = useState();

  return (
    <FormTextField
      {...props}
      autoComplete="off"
      value={displayValue}
      onChange={(e) => {
        // If setLoading prop has been passed, set it to true so loading spinner can begin
        setLoading?.(true);
        const newValue = e.target.value;
        clearTimeout(debounceTimeout);
        setDisplayValue(newValue);
        setDebounceTimeout(
          setTimeout(() => {
            props.onChange(
              { target: { name: props.name, value: newValue } },
              newValue
            );
          }, debounceMs)
        );
      }}
    />
  );
};

export default DebouncedTextField;
