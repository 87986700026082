import { BusinessCenter, Star } from "@mui/icons-material";
import { Avatar, Card, CardActionArea, Chip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { displayName } from "../../../common/util/DisplayUtil";

const BusinessSummary = ({ item }) => {
  const business = item;

  return (
    <Card sx={{ mb: 0.125 }} data-testid={"business-summary"}>
      <CardActionArea
        component={Link}
        to={`/app/business-admin/manage-businesses/${business.id}`}
        sx={{ p: 0.5 }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid xs={2} sm={1} md="auto">
            <Avatar
              src={business.logoPath}
              alt={business.name}
              sx={{ height: 30, width: 30 }}
            >
              <BusinessCenter />
            </Avatar>
          </Grid>
          <Grid xs={10} sm={11} md="auto">
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid container direction="column">
                <Typography
                  variant="body1"
                  sx={{
                    textOverflow: "ellipsis",
                    overflowX: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    display: "block",
                    textDecoration: "underline",
                    width: { xs: 200, sm: 200, md: 300 },
                    fontWeight: 500,
                  }}
                  data-testid="business-name"
                >
                  {business.name}
                </Typography>
              </Grid>
              <Grid
                container
                spacing={1}
                xs="auto"
                sx={{ width: { xs: 200, sm: 200 } }}
              >
                {business.supplierStatus === "yes" && (
                  <Grid>
                    <Chip
                      data-testid="active-supplier-badge"
                      label={
                        "Grower" +
                        (business.supplierCode
                          ? ` - ${business.supplierCode}`
                          : "")
                      }
                      color="success"
                      size="small"
                    />
                  </Grid>
                )}
                {business.buyerStatus === "yes" && (
                  <Grid>
                    <Chip
                      data-testid="active-buyer-badge"
                      label={
                        "Florist" +
                        (business.buyerCode ? ` - ${business.buyerCode}` : "")
                      }
                      color="info"
                      size="small"
                    />
                  </Grid>
                )}
              </Grid>
              <Grid xs="auto">
                <Typography
                  variant="subtitle2"
                  sx={{
                    textOverflow: "ellipsis",
                    overflowX: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    display: "block",
                  }}
                  data-testid="owner-name"
                >
                  {displayName(business.ownerFirstName, business.ownerLastName)}
                </Typography>
              </Grid>
              <Grid sx={{ width: 60 }}>
                {business.platinumMember && (
                  <Star data-testid="platinum-member-badge" color="default" />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default BusinessSummary;
