import { Warning } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";

export default function ProductAvailabilityIndicator({ product }) {
  switch (product?.availabilityStatus) {
    case "Unavailable":
      return (
        <Typography
          color="error"
          style={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          <Warning />
          Unavailable
        </Typography>
      );
    default:
      return <span />;
  }
}
