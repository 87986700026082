import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";

export default function AttachmentPreviewDialog({
  open,
  dialogTitle,
  imageUrl,
  onClose,
  onDeleteClick,
  onClickNext,
  onClickPrev,
}) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && onClickPrev) {
        onClickPrev();
      } else if (event.key === "ArrowRight" && onClickNext) {
        onClickNext();
      }
    };

    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, onClickPrev, onClickNext]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {onClickPrev && (
            <IconButton
              onClick={onClickPrev}
              style={{ position: "absolute", left: 0 }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <img
            src={imageUrl}
            alt={dialogTitle}
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
          {onClickNext && (
            <IconButton
              onClick={onClickNext}
              style={{ position: "absolute", right: 0 }}
            >
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {onDeleteClick && (
          <Button
            onClick={onDeleteClick}
            color="secondary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
