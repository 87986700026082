import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormHelperText, IconButton, TextField } from "@mui/material";
import { ErrorMessage } from "formik";
import { useState } from "react";

export function FormTextField({ label, ...props }) {
  const autoCapitalize =
    props.autoCapitalize ||
    (["email", "password"].includes(props.type) ? "off" : "sentences");

  function handleChange(event) {
    let newValue = event.target.value;
    if (!props.value && newValue?.length === 1 && autoCapitalize !== "off") {
      // Capitalize the first letter of the input
      newValue = newValue.toUpperCase();
    }
    return props.onChange({ target: { name: props.name, value: newValue } });
  }

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <TextField
        label={label}
        size="small"
        fullWidth
        id={props.name}
        data-testid={props.name}
        autoCapitalize={autoCapitalize || "off"}
        InputProps={{
          endAdornment:
            props.type === "password" ? (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                tabIndex={-1}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ) : null,
        }}
        {...props}
        type={props.type === "password" && showPassword ? "text" : props.type}
        onChange={handleChange}
        value={props.value || ""}
      />
      <ErrorMessage name={props.name}>
        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
}
