import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { useCollective } from "../../common/context/CollectiveContext";

export default function LandingPage() {
  const { collectiveInfo } = useCollective();

  return (
    <Box
      sx={{
        bgcolor: "secondary.light",
        color: "white",
        minHeight: 400,
        height: "100%",
        overflowY: "auto",
        backgroundImage:
          'url("/img/picklinq-official/pattern/Picklinq_Pattern.png")',
      }}
    >
      <Container sx={{ pt: 12 }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h1"
              sx={{ fontSize: 85, fontWeight: "600" }}
              gutterBottom
            >
              {collectiveInfo?.name}
            </Typography>

            <Typography variant="h5">
              <em>The collective's inventory & sales hub</em>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <main style={{ marginTop: 32 }}>
              <Paper
                elevation={1}
                style={{ width: "fit-content", margin: "auto", padding: 12 }}
              >
                <Outlet />
              </Paper>
            </main>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
