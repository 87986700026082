import React from "react";

import { Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";

export default function SupplierHome() {
  return (
    <Container
      style={{ textAlign: "center", marginTop: "20px" }}
      data-testid="supplier-home-container"
    >
      <Typography variant="h4" gutterBottom data-testid="supplier-home-title">
        Supplier Home
      </Typography>
      <Grid container spacing={2} direction="column">
        <Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="product-requests"
            style={{ marginTop: "20px" }}
            data-testid="fill-product-requests-button"
          >
            Fill Product Requests
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="upload-presale"
            style={{ marginTop: "20px" }}
            data-testid="upload-presale-button"
          >
            Upload Inventory
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="bring-to-market"
            style={{ marginTop: "20px" }}
            data-testid="bring-to-market"
          >
            What to Bring to Market
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
