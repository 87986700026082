import { Container, Typography } from "@mui/material";
import React from "react";

export default function NoPermissionPage() {
  return (
    <Container sx={{ mt: "20vh" }}>
      <Typography variant="h3" align="center">
        You do not have permission to view this page
      </Typography>
      <Typography variant="h5" align="center">
        If your permissions have recently changed, please log out and log back
        in. Otherwise, if you believe this is an error, please contact your
        administrator.
      </Typography>
    </Container>
  );
}
