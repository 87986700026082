import { AddPhotoAlternate } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Field, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { FormTextField } from "../../common/components/form/FormTextField";
import { useAlerts } from "../../common/context/AlertContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import AttachmentPreviewDialog from "./AttachmentPreviewDialog";

export default function SupplementalInfoForm({
  advancedRequest,
  setAdvancedRequest,
  isNew,
  setFormikRef,
}) {
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const formikRef = React.useRef();
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const [selectedAttachmentIdx, setSelectedAttachmentIdx] =
    React.useState(null);

  React.useEffect(() => {
    setFormikRef(formikRef);
  }, [setFormikRef]);

  const handleSubmit = async (requestToSave) => {
    return AdvancedRequestService.updateAdvancedRequest(
      advancedRequest.id,
      requestToSave
    )
      .then((updatedRequest) => {
        formikRef.current?.resetForm({ values: updatedRequest });
        return updatedRequest;
      })
      .catch((err) => addErrorAlert("Error saving changes", err));
  };

  const maxAttachments = 5;
  const canUploadMoreImages =
    advancedRequest.attachments?.length < maxAttachments;

  return (
    <Formik
      initialValues={advancedRequest}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validationSchema={Yup.object().shape({
        pickupDate: Yup.date().required("Pickup date is required"),
        orderReference: Yup.string().required("Order reference is required"),
        buyerBusiness: Yup.object().required("Buyer business is required"),
      })}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Grid container rowSpacing={2} sx={{ mb: 2 }}>
            <Grid xs={12} md={10} mdOffset={1} lgOffset={2} lg={8}>
              <Field
                as={FormTextField}
                name="notes"
                label="Request Notes"
                multiline
                rows={4}
              />
              <FormHelperText>
                Your request will be reviewed by the Picklinq team. If you have
                substitution recommendations for unavailable products please
                indicate.
              </FormHelperText>
            </Grid>
            <Grid mdOffset={1} lgOffset={2}>
              <Tooltip
                title={
                  canUploadMoreImages
                    ? undefined
                    : `Only ${maxAttachments} images can be uploaded to your order`
                }
              >
                <div>
                  <Button
                    onClick={() =>
                      document.getElementById("ref-image-input").click()
                    }
                    variant="contained"
                    disabled={uploadingImage || !canUploadMoreImages}
                    startIcon={
                      uploadingImage ? (
                        <CircularProgress
                          style={{ width: "20px", height: "20px" }}
                          color="inherit"
                        />
                      ) : (
                        <AddPhotoAlternate sx={{ mr: 1 }} />
                      )
                    }
                  >
                    Upload Reference Image
                  </Button>
                </div>
              </Tooltip>
              <input
                id="ref-image-input"
                type="file"
                accept="image"
                hidden
                onChange={async (e) => {
                  setUploadingImage(true);
                  try {
                    const uploadedReferenceImage =
                      await AdvancedRequestService.addReferenceImage(
                        advancedRequest.id,
                        e.target.files[0]
                      );
                    setAdvancedRequest({
                      ...advancedRequest,
                      attachments: [
                        ...advancedRequest.attachments,
                        uploadedReferenceImage,
                      ],
                    });
                    addSuccessAlert("Reference picture uploaded");
                  } catch (error) {
                    addErrorAlert("Error uploading image", error);
                  } finally {
                    setUploadingImage(false);
                  }
                }}
              />
            </Grid>
            <Grid
              container
              spacing={3}
              xs={12}
              md={10}
              mdOffset={1}
              lgOffset={2}
              lg={8}
            >
              {advancedRequest.attachments.map((attachment, idx) => (
                <Grid xs={6} sm={3} key={attachment.id}>
                  <Card>
                    <CardActionArea
                      onClick={() => setSelectedAttachmentIdx(idx)}
                    >
                      <CardMedia
                        component="img"
                        src={attachment.attachmentPath}
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <AttachmentPreviewDialog
              open={selectedAttachmentIdx !== null}
              onClose={() => setSelectedAttachmentIdx(null)}
              imageUrl={
                advancedRequest.attachments?.[selectedAttachmentIdx]
                  ?.attachmentPath
              }
              dialogTitle={`Reference Image ${selectedAttachmentIdx + 1}`}
              onClickNext={
                advancedRequest.attachments.length > 1
                  ? () => {
                      setSelectedAttachmentIdx(
                        (selectedAttachmentIdx + 1) %
                          advancedRequest.attachments.length
                      );
                    }
                  : undefined
              }
              onClickPrev={
                advancedRequest.attachments.length > 1
                  ? () => {
                      setSelectedAttachmentIdx(
                        (selectedAttachmentIdx -
                          1 +
                          advancedRequest.attachments.length) %
                          advancedRequest.attachments.length
                      );
                    }
                  : undefined
              }
            />
          </Grid>
        );
      }}
    </Formik>
  );
}
