import { Paper } from "@mui/material";
import { Field } from "formik";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";

export default function AgedOutStockSearchCriteria() {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
      }}
      elevation={0}
    >
      <div>
        <Field
          as={FormDatePicker}
          name="dateAgedOut"
          label="Date Aged Out"
          sx={{ maxWidth: 180, width: "100%" }}
          size="small"
        />
      </div>
    </Paper>
  );
}
