import { Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import DebouncedTextField from "../../common/components/form/DebouncedTextField";

export default function AdvancedRequestProductSearchCriteria() {
  return (
    <Paper sx={{ position: "sticky", top: 0, zIndex: 100, p: 1 }} elevation={0}>
      <Field
        as={DebouncedTextField}
        label="Search..."
        name="searchText"
        sx={{ maxWidth: 300, width: "100%" }}
      />
    </Paper>
  );
}
