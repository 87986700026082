import { Close, ShoppingCart } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Fab,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import SlideUpTransition from "../../common/components/animations/SlideUpTransition";
import PaginatedSearchResults from "../../common/components/data-display/PaginatedSearchResults";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import ProductQuantityChip from "../../common/components/product/ProductQuantityChip";
import BaseProductService from "../../common/service/BaseProductService";
import { displayPrice } from "../../common/util/DisplayUtil";
import AdvancedProductDialog from "./AdvancedProductDialog";
import AdvancedProductSummary from "./AdvancedProductSummary";
import AdvancedRequestProductSearchCriteria from "./AdvancedRequestProductSearchCriteria";

export default function AdvancedRequestProductForm({
  advancedRequest,
  setAdvancedRequest,
}) {
  const [selectedBaseProduct, setSelectedBaseProduct] = React.useState(null);
  const [selectedProductInstance, setSelectedProductInstance] =
    React.useState(null);
  const [mobileCartOpen, setMobileCartOpen] = React.useState(false);

  const locationState = useLocation().state;
  const lastSearchedCriteria = locationState?.lastSearchedCriteria;
  const defaultSearchCriteria = lastSearchedCriteria || {
    searchText: "",
    activeStatus: "active",
    page: 1,
    itemsPerPage: 24,
  };

  const cartItems = (
    <Box
      data-testid={"advanced-request-product-cart"}
      sx={{
        position: "relative",
        width: "100%",
        overflowY: "auto",
        maxHeight: {
          xs: "100%",
          sm: "calc(100vh - 200px)",
        },
        textAlign: "center",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ marginLeft: "auto", marginRight: "auto" }}
      >
        Requested Products
      </Typography>
      {advancedRequest.requestProducts.length === 0 ? (
        <Typography variant="body1" sx={{ p: 1 }}>
          No products requested
        </Typography>
      ) : (
        advancedRequest.requestProducts.map((product, index) => (
          <ProductInstanceSummary
            key={product.id}
            productInstance={product}
            summaryComponents={[ProductQuantityChip]}
            onClick={() => {
              setSelectedBaseProduct(null);
              setSelectedProductInstance(product);
            }}
          />
        ))
      )}
      <Paper
        data-testid="estimated-total-container"
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
          borderTop: 1,
          borderColor: "divider",
          p: 2,
          textAlign: "end",
          zIndex: 1,
        }}
        elevation={0}
      >
        <Typography variant="h6">
          Estimated Total: {displayPrice(advancedRequest.estimatedTotal)}
        </Typography>
      </Paper>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <Box
          data-testid="advanced-request-product-search"
          sx={{
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
            display: "flex",
            overflowY: "auto",
            paddingBottom: "250px",
            minWidth: 100,
            maxHeight: "100vh", // Ensure the Box takes the full viewport height
          }}
        >
          <Typography variant="h5" gutterBottom>
            Search for Products
          </Typography>
          <Box sx={{ flex: "auto", p: 0.5 }}>
            <PaginatedSearchResults
              fetchSearchResults={BaseProductService.getBaseProducts}
              ResultDisplayComponent={AdvancedProductSummary}
              SearchCriteriaComponent={AdvancedRequestProductSearchCriteria}
              defaultSearchCriteria={defaultSearchCriteria}
              SearchResultsProps={{
                sx: {
                  display: "flex",
                  gap: 2.5,
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                },
              }}
              PaginationProps={{
                itemsPerPageOptions: [
                  { label: "24", value: 24 },
                  { label: "48", value: 48 },
                  { label: "100", value: 100 },
                ],
              }}
              onClickSearchResult={(product) => {
                setSelectedBaseProduct(product);
                setSelectedProductInstance(null);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: { xs: "none", sm: "inherit" },
            width: { xs: 0, sm: 280, md: 350 },
            maxWidth: { xs: 0, sm: 280, md: 350 },
            flexShrink: 0,
            height: "100vh",
            overflowY: "auto",
            paddingBottom: "20px",
            maxHeight: "100%",
          }}
        >
          {cartItems}
        </Box>
      </Box>
      <Toolbar
        sx={{
          backgroundColor: "secondary.light",
          display: { xs: "flex", sm: "none" },
          position: "fixed",
          right: 0,
          bottom: 52.5,
          width: "calc(100% - 56px)",
          m: 0,
          p: 0,
        }}
      >
        <Button
          onClick={() => setMobileCartOpen(true)}
          startIcon={<ShoppingCart />}
          sx={{
            width: "100%",
            display: "flex",
            margin: "auto",
            alignSelf: "stretch",
          }}
          size="large"
        >
          View Cart - {displayPrice(advancedRequest.estimatedTotal)}
        </Button>
      </Toolbar>
      <Dialog
        open={mobileCartOpen}
        onClose={() => setMobileCartOpen(false)}
        fullScreen
        TransitionComponent={SlideUpTransition}
      >
        <DialogContent sx={{ position: "relative", p: 0, m: 2 }}>
          <Fab
            sx={{ position: "fixed", right: 16, top: 16 }}
            onClick={() => setMobileCartOpen(false)}
            size="small"
          >
            <Close />
          </Fab>
          {cartItems}
        </DialogContent>
      </Dialog>
      <AdvancedProductDialog
        advancedRequest={advancedRequest}
        baseProduct={selectedBaseProduct}
        productInstance={selectedProductInstance}
        isOpen={Boolean(selectedBaseProduct || selectedProductInstance)}
        onClose={() => {
          setSelectedBaseProduct(null);
          setSelectedProductInstance(null);
        }}
        onAddProduct={(updatedRequest) => {
          setAdvancedRequest({ ...updatedRequest });
          setSelectedBaseProduct(null);
          setSelectedProductInstance(null);
        }}
        onDeleteProduct={() => {
          const priceToSubtract =
            selectedProductInstance?.estimatedUnitPrice *
              selectedProductInstance?.quantityRequested || 0;
          // Remove the selected product from the request and
          // subtract its price from the estimated total
          setAdvancedRequest({
            ...advancedRequest,
            requestProducts: advancedRequest.requestProducts.filter(
              (product) => product.id !== selectedProductInstance.id
            ),
            estimatedTotal: advancedRequest.estimatedTotal - priceToSubtract,
          });
          setSelectedBaseProduct(null);
          setSelectedProductInstance(null);
        }}
      />
    </>
  );
}
