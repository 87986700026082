import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import { PICKLINQ_FLOWER_COLORS } from "./FormFlowerColorPicker";

export default function SearchCriteriaColorPicker({ ...props }) {
  return (
    <FormControl sx={{ width: 220 }} size={props.size || "small"}>
      <InputLabel id="color-search-label">{props.label}</InputLabel>
      <Field
        as={Select}
        labelId="color-search-label"
        id="color-search"
        label={props.label}
        multiple
        renderValue={(selected) => {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected?.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{
                    backgroundColor: PICKLINQ_FLOWER_COLORS.find(
                      (c) => c.name === value
                    ).hexValue,
                    color: PICKLINQ_FLOWER_COLORS.find((c) => c.name === value)
                      .textHexValue,
                  }}
                  size="small"
                />
              ))}
            </Box>
          );
        }}
        {...props}
      >
        {PICKLINQ_FLOWER_COLORS.map((color) => (
          <MenuItem value={color.name} key={color.name}>
            <Chip
              label={color.name}
              sx={{
                backgroundColor: color.hexValue,
                color: color.textHexValue,
              }}
            />
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
}
