export const DefaultUom = {
  Bunch: "Bunch",
  StemSprays: "Stem Sprays",
  Stems: "Stem(s)",
  Unit: "Unit",
};

export const DefaultUomOptions = [
  { value: DefaultUom.Bunch, label: "Bunch" },
  { value: DefaultUom.StemSprays, label: "Stem Sprays" },
  { value: DefaultUom.Stems, label: "Stem(s)" },
  { value: DefaultUom.Unit, label: "Unit" },
];
