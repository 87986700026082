import { createTheme, ThemeProvider } from "@mui/material";
import React, { createContext, useContext, useState } from "react";

const serifFonts = ["Bell-MT", "Times New Roman", "Times", "serif"].join();
const sansSerifFonts = [
  "Semplitica-Pro",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");

const defaultTheme = {
  palette: {
    primary: {
      main: "#202f24", // Black color for primary
    },
    secondary: {
      main: "#e4c568", // Yellow color for secondary
    },
    yellow: {
      main: "#D9CF8D",
      contrastText: "#000",
    },
    white: {
      main: "#F0F0F0",
      contrastText: "#000",
    },
    purple: {
      main: "#7F6E8D",
      contrastText: "#ffffff",
    },
    pink: {
      main: "#C18D99",
      contrastText: "#000",
    },
    red: {
      main: "#9B4E4E",
      contrastText: "#ffffff",
    },
    orange: {
      main: "#D18660",
      contrastText: "#ffffff",
    },
    blue: {
      main: "#5E7A8A",
      contrastText: "#ffffff",
    },
    green: {
      main: "#6E8E6A",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: sansSerifFonts,
    h1: {
      fontFamily: serifFonts,
      fontSize: 40,
    },
    h2: {
      fontFamily: serifFonts,
      fontSize: 36,
    },
    h3: {
      fontFamily: serifFonts,
      fontSize: 32,
    },
    h4: {
      fontFamily: serifFonts,
      fontSize: 28,
    },
    h5: {
      fontFamily: serifFonts,
      fontSize: 24,
    },
    h6: {
      fontFamily: serifFonts,
      fontSize: 20,
      fontWeight: 500,
    },
  },
};

const AppThemeContext = createContext();

export const AppThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(createTheme(defaultTheme));
  const [primaryColor, setPrimaryColor] = useState("#202f24");
  const [secondaryColor, setSecondaryColor] = useState("#e4c568");
  React.useEffect(() => {
    setTheme(
      createTheme({
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          primary: {
            main: primaryColor,
          },
          secondary: {
            main: secondaryColor,
          },
        },
      })
    );
  }, [primaryColor, secondaryColor]);

  return (
    <AppThemeContext.Provider value={{ setPrimaryColor, setSecondaryColor }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppThemeContext.Provider>
  );
};

export const useAppTheme = () => useContext(AppThemeContext);
