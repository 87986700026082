import { Avatar, Badge, Card, CardActionArea, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Link } from "react-router-dom";

const BaseProductSummary = ({ item }) => {
  const baseProduct = item;

  return (
    <Card sx={{ mb: 0.125 }} data-testid={"base-product-summary"}>
      <CardActionArea
        component={Link}
        to={`/app/business-admin/manage-base-products/${baseProduct.id}`}
        sx={{ p: 0.5 }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid xs={2} sm={1} md="auto">
            <Badge
              invisible={baseProduct.active}
              data-testid={baseProduct.active ? "" : "product-inactive-badge"}
              badgeContent="📛"
            >
              <Avatar
                src={baseProduct.productPicturePath}
                alt={baseProduct.name}
                sx={{ height: 30, width: 30 }}
              />
            </Badge>
          </Grid>
          <Grid xs={10} sm={11} md="auto">
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid container direction="column">
                <Typography
                  variant="body1"
                  sx={{
                    textOverflow: "ellipsis",
                    overflowX: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    display: "block",
                    textDecoration: "underline",
                    width: { xs: 200, sm: 200, md: 300 },
                    fontWeight: 500,
                  }}
                  data-testid="base-product-name"
                >
                  {baseProduct.name}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {baseProduct.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default BaseProductSummary;
