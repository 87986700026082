import { Warning } from "@mui/icons-material";
import { Box, ListItemButton, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { displayPrice } from "../../common/util/DisplayUtil";

export function AdminAdvancedRequestSummary({ ...props }) {
  return <AdvancedRequestSummary {...props} adminView />;
}

export default function AdvancedRequestSummary({
  advancedRequest,
  item,
  adminView = false,
  ...props
}) {
  const request = advancedRequest || item;
  return (
    <ListItemButton
      sx={{
        mb: 1,
        p: 1,
        width: "100%",
        maxWidth: "fit-content",
        display: "flex",
        alignItems: "center",
        borderRadius: 1,
        border: "1px solid #E0E0E0",
      }}
      data-testid={"advanced-request-summary"}
      LinkComponent={Link}
      to={
        (adminView
          ? `/app/business-admin/manage-request/${request.id}`
          : `/app/buyer/product-requests/${request.id}`) +
        (request.fulfillmentStatus === "DRAFT" ? "/edit" : "")
      }
      aria-label={`View request ${request.orderReference ?? ` for ${moment(request.pickupDate).format("M/D/YY")}`}`}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          minWidth: 0,
          maxWidth: "fit-content",
          ml: 2,
          mr: 0,
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <Typography
          variante="body1"
          sx={{ fontWeight: 800, width: 150, display: "inline-block" }}
          data-testid="request-pickup-date"
        >
          {/* display date with day of week, day month year */}
          {moment(request.pickupDate).format("ddd, M/D/YY")}
        </Typography>

        {adminView && (
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflowX: "hidden",
              whiteSpace: "nowrap",
              display: "inline-block",
              width: 300,
            }}
            data-testid="request-buyer-business-name"
          >
            {request.buyerBusinessName}
          </Typography>
        )}
        <Typography
          sx={{
            textOverflow: "ellipsis",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            display: "inline-block",
            width: 300,
          }}
          data-testid="request-order-reference"
        >
          {request.orderReference}
        </Typography>
        {request.fulfillmentStatus === "DRAFT" ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Warning color="warning" sx={{ marginRight: 1.5 }} />
            <Tooltip title="This request is a draft and has not been submitted">
              <Typography variant="body1" color="warning">
                Draft
              </Typography>
            </Tooltip>
          </Box>
        ) : (
          ""
        )}
        <Typography
          variante="body1"
          sx={{
            alignSelf: "flex-end",
            fontWeight: 800,
            width: 120,
            display: "inline-block",
            textAlign: "end",
          }}
          data-testid="request-estimated-total"
        >
          {displayPrice(request.estimatedTotal)}
        </Typography>
      </Box>
    </ListItemButton>
  );
}
