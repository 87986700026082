import { Avatar, Card, CardActionArea, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { displayName } from "../../../common/util/DisplayUtil";

const UserSummary = ({ item }) => {
  const user = item;

  return (
    <Card sx={{ mb: 0.125 }} data-testid={"user-summary"}>
      <CardActionArea
        component={Link}
        to={`/app/business-admin/manage-users/${user.id}`}
        sx={{ p: 0.5 }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid xs={2} sm={1} md="auto">
            <Avatar
              src={user.profilePicPath}
              alt={displayName(user.firstName, user.lastName)}
              sx={{ height: 30, width: 30 }}
            />
          </Grid>
          <Grid xs={10} sm={11} md="auto">
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid container direction="column">
                <Typography
                  variant="body1"
                  sx={{
                    textOverflow: "ellipsis",
                    overflowX: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    display: "block",
                    textDecoration: "underline",
                    width: { xs: 200, sm: 200, md: 300 },
                    fontWeight: 500,
                  }}
                  data-testid="user-name"
                >
                  {displayName(user.firstName, user.lastName)}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">{user.email}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default UserSummary;
