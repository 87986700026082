import { FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ErrorMessage } from "formik";
import moment from "moment";

export function FormDatePicker({ ...props }) {
  const standardizedValue = props.value
    ? typeof props.value === "string" // If the value is a string, convert it to a moment object
      ? moment(props.value)
      : props.value
    : null;
  return (
    <>
      <DatePicker
        data-testid={props.name}
        sx={{ width: "100%" }}
        size="small"
        {...props}
        onChange={(newValue) => {
          props.onChange({ target: { name: props.name, value: newValue } });
        }}
        slotProps={{
          field: {
            shouldRespectLeadingZeros: true,
          },
          textField: {
            id: props.name,
            fullWidth: props.fullWidth,
            size: props.size,
            variant: props.variant || "outlined",
            error: !!props.error,
            helperText: props.error?.message,
          },
        }}
        value={standardizedValue}
      />
      <ErrorMessage name={props.name}>
        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
}
