import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormTextField } from "../../../common/components/form/FormTextField";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary";
import { useAlerts } from "../../../common/context/AlertContext";
import { displayPrice } from "../../../common/util/DisplayUtil";

const ProductQuantitySelectDialog = ({
  open,
  onClose,
  onEditQuantity,
  productInstance,
  onAddProduct,
}) => {
  const { addErrorAlert } = useAlerts();
  // An item that is already in the cart will have a presaleOrderItemId
  const isEditing = Boolean(productInstance?.presaleOrderItemId);
  const validationSchema = Yup.object({
    quantity: Yup.number()
      .min(1, "Quantity must be greater than 0")
      .required("Quantity is required")
      .test("quantity-available", "Quantity exceeds available", (value) => {
        if (!productInstance.quantityAvailable) {
          return true;
        }
        return value <= productInstance.quantityAvailable;
      }),
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="product-quantity-select-dialog"
    >
      <DialogTitle data-testid="dialog-title">
        Select Product Quantity
      </DialogTitle>
      <Formik
        initialValues={{ quantity: isEditing ? productInstance?.quantity : 1 }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (isEditing) {
              return await onEditQuantity({
                ...productInstance,
                quantity: values.quantity,
              });
            } else {
              return await onAddProduct({
                productInstanceId: productInstance.id,
                quantity: values.quantity,
                unitPrice: productInstance.estimatedUnitPrice,
              });
            }
          } catch (err) {
            addErrorAlert("Error completing request", err);
          }
        }}
      >
        {({ isSubmitting, values }) => {
          const unitCost =
            productInstance.estimatedUnitPrice ||
            productInstance.unitPrice ||
            0;
          return (
            <Form>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <ProductInstanceSummary item={productInstance} />
                  <Box>
                    {!isEditing && (
                      <Typography textAlign="end">
                        Quantity Available: {productInstance.quantityAvailable}
                      </Typography>
                    )}
                  </Box>
                  {unitCost && (
                    <Typography variant="body2" textAlign="end">
                      Unit Cost: {displayPrice(unitCost)}
                    </Typography>
                  )}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Field
                        autoFocus
                        as={FormTextField}
                        name="quantity"
                        type="number"
                        label="Quantity"
                        sx={{ width: 100 }}
                      />
                    </div>
                  </Box>
                  <Typography textAlign="end">
                    {unitCost && values.quantity && (
                      <strong>
                        Total: {displayPrice(unitCost * values.quantity)}
                      </strong>
                    )}
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button
                  onClick={onClose}
                  color="primary"
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? <CircularProgress size={16} /> : null
                  }
                  data-testid="submit-quantity-button"
                >
                  {isEditing ? "Update Cart" : "Add to Cart"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ProductQuantitySelectDialog;
