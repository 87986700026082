import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlerts } from "../../../common/context/AlertContext";
import { useCollective } from "../../../common/context/CollectiveContext";
import PresaleOrderService from "../../../common/service/PresaleOrderService";
import { getNextPrimaryMarketDate } from "../../../common/util/DatePickerUtilFrontend";

const PresaleOrderFormContext = createContext();

export const PresaleOrderFormContextProvider = ({ children }) => {
  const { collectiveInfo } = useCollective();
  const [timeToExpire, setTimeToExpire] = useState(null);
  const [secondsRemaining, setSecondsRemaining] = useState(null);
  const [presaleOrder, setPresaleOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addErrorAlert } = useAlerts();
  const navigate = useNavigate();

  let isNew = false;
  let { orderId } = useParams();
  if (orderId) {
    orderId = parseInt(orderId);
  } else {
    isNew = true;
  }

  // This effect sets up the timer to count down the time remaining on the order
  useEffect(() => {
    let presaleTimingInterval;
    if (!timeToExpire) {
      clearInterval(presaleTimingInterval);
      return;
    }

    presaleTimingInterval = setInterval(() => {
      setSecondsRemaining(() => {
        const now = moment();
        const diff = timeToExpire.diff(now, "seconds");
        if (diff <= 0) {
          // TODO: when secondsRemaining reaches 0, cancel the order (and the timer!)
          clearInterval(presaleTimingInterval);
          navigate("/app/buyer", { state: { cartExpired: true } });
          return 0;
        }
        return diff;
      });
    }, 1000);

    return () => {
      clearInterval(presaleTimingInterval);
    };
  }, [timeToExpire, navigate]);

  useEffect(() => {
    if (isNew) {
      const nextMarketDate = getNextPrimaryMarketDate(collectiveInfo);
      PresaleOrderService.getCurrentPresaleOrder(
        nextMarketDate.format("YYYY-MM-DD")
      )
        .then((order) => {
          setPresaleOrder(order);
          if (
            order.secondsSinceCreated < order.secondsToLive &&
            !order.confirmedDate
          ) {
            setTimeToExpire(
              moment().add(
                order.secondsToLive - order.secondsSinceCreated,
                "seconds"
              )
            );
          }
        })
        .catch((err) => addErrorAlert("Error fetching order", err))
        .finally(() => setLoading(false));
    } else if (orderId && presaleOrder?.id !== orderId) {
      PresaleOrderService.getPresaleOrder(orderId)
        .then(setPresaleOrder)
        .catch((err) => addErrorAlert("Error fetching order", err))
        .finally(() => setLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return (
    <PresaleOrderFormContext.Provider
      value={{
        timeRemainingText: secondsRemaining
          ? `${Math.floor(secondsRemaining / 60)}:${String(secondsRemaining % 60).padStart(2, "0")}`
          : null,
        presaleOrderLoading: loading,
        presaleOrder,
        setPresaleOrder,
      }}
    >
      {children}
    </PresaleOrderFormContext.Provider>
  );
};

export const usePresaleOrderContext = () => useContext(PresaleOrderFormContext);
