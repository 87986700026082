import { Check, Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";

export default function NewPasswordRequirements({ passwordToTest }) {
  const passwordReqs = [
    {
      name: "At least 8 characters",
      regex: /.{8,}/,
    },
    {
      name: "At least one uppercase letter",
      regex: /[A-Z]/,
    },
    {
      name: "At least one lowercase letter",
      regex: /[a-z]/,
    },
    {
      name: "At least one number",
      regex: /\d/,
    },
    {
      name: "At least one special character",
      regex: /[^A-Za-z0-9]/,
    },
  ];

  return (
    <ul style={{ listStyle: "none", padding: 8 }}>
      {passwordReqs.map((req, i) => (
        <li key={i} style={{ display: "flex", alignItems: "center" }}>
          {req.regex.test(passwordToTest) ? (
            <Check
              color={"success"}
              sx={{ display: "inline-block", height: 14, width: 14 }}
            />
          ) : (
            <Close
              color={"disabled"}
              sx={{ display: "inline-block", height: 14, width: 14 }}
            />
          )}
          <Typography variant="body2" sx={{ ml: 1, display: "inline-block" }}>
            {req.name}
          </Typography>
        </li>
      ))}
    </ul>
  );
}
