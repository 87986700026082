import { Container, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import SalesLogService from "../../../common/service/SalesLogService";
import ManageSalesLogsSearchCriteria from "./ManageSalesLogsSearchCriteria";
import SalesLogSummary from "./SalesLogSummary";

export default function ManageSalesLogs() {
  const locationState = useLocation().state;
  const lastSearchedCriteria = locationState?.lastSearchedCriteria;
  const defaultSearchCriteria = lastSearchedCriteria || {
    invoiceNumber: "",
    page: 1,
    itemsPerPage: 10,
    buyerBusinesses: [],
    saleDate: null,
    productInSale: null,
  };

  return (
    <Container data-testid="manage-sales-logs-page">
      <Typography variant="h1">Sales Logs</Typography>
      <PaginatedSearchResults
        fetchSearchResults={SalesLogService.getSalesLogs}
        defaultSearchCriteria={defaultSearchCriteria}
        SearchCriteriaComponent={ManageSalesLogsSearchCriteria}
        ResultDisplayComponent={SalesLogSummary}
        validationSchema={Yup.object().shape({
          invoiceNumber: Yup.string(),
          buyerBusinesses: Yup.array(),
          saleDate: Yup.date()
            .nullable()
            .typeError("Invalid Date")
            .min("2000-01-01"),
        })}
        normalizeSearchCriteria={(criteria) => {
          const cleanedCriteria = Object.fromEntries(
            Object.entries(criteria).filter(([_, v]) => v)
          );
          if (
            cleanedCriteria.saleDate &&
            typeof cleanedCriteria.saleDate !== "string"
          ) {
            cleanedCriteria.saleDate =
              cleanedCriteria.saleDate.format("YYYY-MM-DD");
          }
          return cleanedCriteria;
        }}
      />
    </Container>
  );
}
