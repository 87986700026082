import { Typography } from "@mui/material";
import React from "react";

export default function TypographyEllipsis({ children, sx, ...props }) {
  return (
    <Typography
      sx={{
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "100%",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}
