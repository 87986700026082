import { Chip } from "@mui/material";
import React from "react";

export default function ProductQuantityChip({ product }) {
  return (
    <Chip
      sx={{ ml: 1, mr: 1, width: "fit-content", textAlign: "end" }}
      data-testid="product-instance-quantity"
      size="small"
      label={`×${product.quantityRequested || product.quantity}`}
      color={
        product.quantityFulfilled &&
        product.quantityFulfilled === product.quantityRequested
          ? "success"
          : product.quantityFulfilled === undefined
            ? "default"
            : "info"
      }
    />
  );
}
