import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { FormTextField } from "../../common/components/form/FormTextField";
import { useAlerts } from "../../common/context/AlertContext";
import { AuthenticationService } from "../../common/service/AuthenticationService";
const ForgotPasswordPage = () => {
  const { addErrorAlert } = useAlerts();
  const [emailSent, setEmailSent] = useState(false);
  const location = useLocation();

  const handleSubmit = async (values) => {
    return AuthenticationService.sendPasswordResetEmail(values.email)
      .then(() => setEmailSent(true))
      .catch((err) => {
        addErrorAlert("Error completing request", err);
      });
  };

  return (
    <Container maxWidth="xs">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {emailSent ? (
          <Alert
            severity="success"
            sx={{ width: "fit-content", margin: "auto" }}
          >
            <Typography>
              If an account exists with that email address, an email has been
              sent with password reset instructions.
            </Typography>
            <br />
            <Link to="/login">
              <Typography>Return to login</Typography>
            </Link>
          </Alert>
        ) : (
          <Alert severity="info" sx={{ width: "fit-content", margin: "auto" }}>
            Enter your email address to receive a password reset link.
          </Alert>
        )}
        {!emailSent && (
          <Formik
            initialValues={{ email: location.state?.email || "" }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
            })}
          >
            {({ isSubmitting }) => (
              <Form style={{ width: "100%", marginTop: "24px" }}>
                <Grid container spacing={2} justifyContent={"center"}>
                  <Grid item xs={12}>
                    <Field
                      as={FormTextField}
                      autoFocus
                      name="email"
                      variant="outlined"
                      label="Email"
                      type="email"
                      size="medium"
                      required
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      id="reset-pw-button"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress
                            size="small"
                            style={{ height: 16, width: 16 }}
                            color="inherit"
                          />
                        ) : undefined
                      }
                    >
                      {isSubmitting
                        ? "Sending email..."
                        : "Send Password Reset Email"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Container>
  );
};

export default ForgotPasswordPage;
