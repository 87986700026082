import { Avatar, Box, Link, Typography } from "@mui/material";
import React from "react";
import { useAuthContext } from "../../context/AuthContext";
import SelectBusinessDialog from "../dialogs/SelectBusinessDialog";

export default function SelectableBusinessDisplay({
  selectedBusiness,
  setSelectedBusiness,
  buyersOnly,
  suppliersOnly,
  onlyUsersBusinesses,
  allowChangingDefault,
  ...props
}) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const {
    supplierBusinesses,
    buyerBusinesses,
    setDefaultSupplier,
    setDefaultBuyer,
  } = useAuthContext();

  React.useEffect(() => {
    const businesses = buyersOnly ? buyerBusinesses : supplierBusinesses;
    // If there is only one business, select it
    if (!selectedBusiness && businesses.length === 1) {
      setSelectedBusiness(businesses[0]);
    } else if (!selectedBusiness && businesses.length > 1) {
      setDialogOpen(true);
    }
  }, [
    buyerBusinesses,
    buyersOnly,
    selectedBusiness,
    setSelectedBusiness,
    supplierBusinesses,
  ]);

  return (
    <Box>
      {selectedBusiness && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <Avatar
            src={selectedBusiness.logoPath}
            sx={{ height: 36, width: 36, mr: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              fontSize: "120%",
            }}
          >
            <Typography variant="body1">{selectedBusiness.name}</Typography>
            {supplierBusinesses.length > 1 && (
              <Link
                variant="caption"
                component="button"
                onClick={() => setDialogOpen(true)}
                href="#"
              >
                Change Business
              </Link>
            )}
          </Box>
        </Box>
      )}
      <SelectBusinessDialog
        open={!selectedBusiness || dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSelect={(businessToSelect, makeDefault) => {
          // If the user is an admin, we don't want to set the default supplier
          if (makeDefault && suppliersOnly) {
            setDefaultSupplier(businessToSelect);
          } else if (makeDefault && buyersOnly) {
            setDefaultBuyer(businessToSelect);
          }
          setSelectedBusiness(businessToSelect);
          setDialogOpen(false);
        }}
        allowChangingDefault={
          onlyUsersBusinesses &&
          allowChangingDefault &&
          (buyersOnly || suppliersOnly)
        }
        currentSelectedBusiness={selectedBusiness}
        businessAutocompleteProps={{
          suppliersOnly,
          onlyUsersBusinesses,
          buyersOnly,
        }}
      />
    </Box>
  );
}
