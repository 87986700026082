import { Typography } from "@mui/material";
import React from "react";

export default function ProductSaleFocusDisplay({ product }) {
  return (
    <Typography
      data-testid="product-instance-sale-focus"
      variant="body2"
      sx={{ ml: 1.5, width: "fit-content", textAlign: "end" }}
    >
      {product.saleFocus}
    </Typography>
  );
}
