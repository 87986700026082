import { Close, Forward, ShoppingCart } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Fab,
  Toolbar,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SlideUpTransition from "../../../common/components/animations/SlideUpTransition";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import SelectableBusinessDisplay from "../../../common/components/data-display/SelectableBusinessDisplay";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary";
import ProductQuantityChip from "../../../common/components/product/ProductQuantityChip";
import ProductSaleFocusDisplay from "../../../common/components/product/ProductSaleFocusDisplay";
import { useAlerts } from "../../../common/context/AlertContext";
import { useAuthContext } from "../../../common/context/AuthContext";
import { useCollective } from "../../../common/context/CollectiveContext";
import BaseProductService from "../../../common/service/BaseProductService";
import StockLogService from "../../../common/service/StockLogService";
import { isValidBringToMarketDay } from "../../../common/util/DatePickerUtilFrontend";
import AdvancedProductSummary from "../../AdvancedRequests/AdvancedProductSummary";
import AdvancedRequestProductSearchCriteria from "../../AdvancedRequests/AdvancedRequestProductSearchCriteria";
import UploadInventoryDialog from "./UploadInventoryDialog";

export default function UploadPresale({ adminView = false }) {
  const { collectiveInfo } = useCollective();
  const { addSuccessAlert, addWarningAlert, addErrorAlert } = useAlerts();
  const { defaultSupplier } = useAuthContext();
  const marketDateRef = React.useRef(null);
  const locationState = useLocation().state;
  const [marketDate, setMarketDate] = React.useState(
    locationState?.marketDate ? moment(locationState?.marketDate) : null
  );
  const [supplier, setSupplier] = React.useState(defaultSupplier || null);
  const [currentWeekInventory, setCurrentWeekInventory] = React.useState(null);
  const [selectedBaseProduct, setSelectedBaseProduct] = React.useState(null);
  const [selectedProductInstance, setSelectedProductInstance] =
    React.useState(null);
  const [mobileCartOpen, setMobileCartOpen] = React.useState(false);
  const navigate = useNavigate();
  const lastSearchedCriteria = locationState?.lastSearchedCriteria;
  const defaultSearchCriteria = lastSearchedCriteria || {
    searchText: "",
    activeStatus: "active",
    page: 1,
    itemsPerPage: 24,
  };

  const cartWidthSm = 280;
  const cartWidthMd = 450;
  const bottomGap = 150;

  const refreshInventory = React.useCallback(() => {
    if (marketDate && supplier) {
      if (!marketDate.isValid()) {
        addWarningAlert("Please select a valid market date");
        return;
      }
      if (marketDate.isBefore(moment().subtract(1, "day"))) {
        addWarningAlert("Cannot upload inventory for a past date");
        return;
      }
      StockLogService.getStockLogs({
        supplierId: supplier.id,
        dateStocked: marketDate.format("YYYY-MM-DD"),
        status: "DRAFT",
      })
        .then(setCurrentWeekInventory)
        .catch((err) => addErrorAlert("Error loading inventory"));
    }
  }, [marketDate, supplier, addErrorAlert, addWarningAlert]);

  async function onSubmitItems() {
    if (!marketDate) {
      marketDateRef.current.focus();
      addWarningAlert("Please select a market day");
      return;
    }

    if (!supplier) {
      addWarningAlert("Please select a supplier");
      return;
    }

    if (!currentWeekInventory?.length) {
      addWarningAlert("Please add products to the inventory");
      return;
    }

    const stockLogs = currentWeekInventory.map((stockLog) => stockLog.id);

    try {
      const marketDateStr = marketDate.format("YYYY-MM-DD");
      await StockLogService.submitProducts(
        marketDateStr,
        supplier.id,
        stockLogs
      );
      addSuccessAlert("Items submitted");
      if (adminView) {
        navigate("/app/business-admin/manage-inventory");
      } else {
        navigate("/app/supplier/bring-to-market", {
          state: { marketDate: marketDateStr },
        });
      }
    } catch (err) {
      addErrorAlert("Error submitting items", err);
    }
  }

  React.useEffect(() => {
    refreshInventory();
  }, [refreshInventory]);

  const cartItems = (
    <Box
      data-testid={"advanced-request-product-cart"}
      sx={{
        position: "relative",
        width: "100%",
        overflowY: "hidden",
        height: "100%",
        maxHeight: {
          xs: "100vh",
          // for sm and up, subtract the height of the app bar
          sm: `calc(100vh - 64px)`,
        },
        borderLeft: {
          xs: "none",
          sm: "1px solid",
        },
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flexGrow: 1, width: "100%", height: 1, overflowY: "auto" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Product to Upload
        </Typography>
        <SelectableBusinessDisplay
          selectedBusiness={supplier}
          setSelectedBusiness={setSupplier}
          suppliersOnly
          onlyUsersBusinesses={adminView ? false : true}
          allowChangingDefault={true}
        />
        {currentWeekInventory?.length === 0 ? (
          <Typography variant="body1" sx={{ p: 1 }}>
            No products added
          </Typography>
        ) : (
          currentWeekInventory?.map((product, index) => (
            <ProductInstanceSummary
              key={index}
              productInstance={product}
              summaryComponents={[ProductSaleFocusDisplay, ProductQuantityChip]}
              onClick={() => {
                setSelectedBaseProduct(null);
                setSelectedProductInstance(product);
              }}
            />
          ))
        )}
      </Box>
      <Button
        color="success"
        sx={{
          flex: "none",
          borderRadius: 0,
          fontSize: "120%",
        }}
        variant="contained"
        size="large"
        fullWidth
        endIcon={<Forward />}
        disabled={!currentWeekInventory?.length}
        onClick={onSubmitItems}
      >
        Submit Items
      </Button>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <Box
          data-testid="advanced-request-product-search"
          sx={{
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
            display: "flex",
            overflowY: "auto",
            paddingBottom: `${bottomGap}px`,
            minWidth: 100,
            maxHeight: "100vh", // Ensure the Box takes the full viewport height
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              p: 2,
              pb: 0,
            }}
          >
            <Typography variant="h4" gutterBottom>
              Upload Inventory
            </Typography>

            <DesktopDatePicker
              label="Market Date"
              autoFocus
              disablePast
              shouldDisableDate={(d) =>
                !isValidBringToMarketDay(d, collectiveInfo)
              }
              value={marketDate}
              onChange={(date) => {
                setMarketDate(date);
              }}
              inputRef={marketDateRef}
              slotProps={{
                field: {
                  shouldRespectLeadingZeros: true,
                },
                textField: {
                  id: "marketDate",
                  name: "marketDate",
                  helperText: !isValidBringToMarketDay(
                    marketDate,
                    collectiveInfo
                  )
                    ? "Select a valid market date"
                    : null,
                  size: "small",
                },
              }}
              maxDate={moment().add(4, "weeks")}
            />
          </Box>
          <Box sx={{ flex: "auto", p: 0.5 }}>
            <PaginatedSearchResults
              fetchSearchResults={BaseProductService.getBaseProducts}
              ResultDisplayComponent={AdvancedProductSummary}
              SearchCriteriaComponent={AdvancedRequestProductSearchCriteria}
              defaultSearchCriteria={defaultSearchCriteria}
              SearchResultsProps={{
                sx: {
                  display: "flex",
                  gap: 2.5,
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                },
              }}
              PaginationProps={{
                itemsPerPageOptions: [
                  { label: "24", value: 24 },
                  { label: "48", value: 48 },
                  { label: "100", value: 100 },
                ],
              }}
              onClickSearchResult={(product) => {
                if (!marketDate) {
                  marketDateRef.current.focus();
                  addWarningAlert("Please select a market day");
                  return;
                }
                setSelectedBaseProduct(product);
                setSelectedProductInstance(null);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: { xs: "none", sm: "inherit" },
            width: { xs: 0, sm: cartWidthSm, md: cartWidthMd },
            maxWidth: { xs: 0, sm: cartWidthSm, md: cartWidthMd },
            flexShrink: 0,
            height: "100vh",
            overflowY: "auto",
            maxHeight: "100%",
          }}
        >
          {cartItems}
        </Box>
      </Box>
      <Toolbar
        sx={{
          backgroundColor: "secondary.light",
          display: { xs: "flex", sm: "none" },
          position: "fixed",
          right: 0,
          bottom: 0,
          width: "calc(100% - 56px)",
          m: 0,
          p: 0,
        }}
      >
        <Button
          onClick={() => setMobileCartOpen(true)}
          startIcon={<ShoppingCart />}
          sx={{
            width: "100%",
            display: "flex",
            margin: "auto",
            alignSelf: "stretch",
          }}
          size="large"
        >
          Review Your Items{" "}
          {currentWeekInventory?.length && `(${currentWeekInventory.length})`}
        </Button>
      </Toolbar>
      <Dialog
        open={mobileCartOpen}
        onClose={() => setMobileCartOpen(false)}
        fullScreen
        TransitionComponent={SlideUpTransition}
      >
        <DialogContent sx={{ position: "relative", p: 0, m: 0 }}>
          <Fab
            sx={{ position: "fixed", right: 16, top: 16 }}
            onClick={() => setMobileCartOpen(false)}
            size="small"
          >
            <Close />
          </Fab>
          {cartItems}
        </DialogContent>
      </Dialog>
      <UploadInventoryDialog
        supplierBusiness={supplier}
        marketDate={marketDate}
        baseProduct={selectedBaseProduct}
        productInstance={selectedProductInstance}
        isOpen={Boolean(selectedBaseProduct || selectedProductInstance)}
        onClose={() => {
          setSelectedBaseProduct(null);
          setSelectedProductInstance(null);
        }}
        onAdd={() => {
          setSelectedBaseProduct(null);
          setSelectedProductInstance(null);
          refreshInventory();
        }}
        onUpdate={(updatedStock) => {
          setCurrentWeekInventory(
            currentWeekInventory.map((stockLog) =>
              stockLog.id === updatedStock.id
                ? { ...stockLog, ...updatedStock }
                : stockLog
            )
          );
          setSelectedBaseProduct(null);
          setSelectedProductInstance(null);
        }}
        onDelete={() => {
          setCurrentWeekInventory(
            currentWeekInventory.filter(
              (product) => product.id !== selectedProductInstance.id
            )
          );

          setSelectedBaseProduct(null);
          setSelectedProductInstance(null);
        }}
      />
    </>
  );
}
