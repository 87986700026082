import React from "react";
import ErrorLogService from "../service/ErrorLogService";

class MainErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    // Log the error using ErrorService.logError
    ErrorLogService.logError(error, "An error occurred in the application");
  }

  render() {
    return this.props.children;
  }
}

export default MainErrorBoundary;
