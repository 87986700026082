import { Typography } from "@mui/material";
import React from "react";
import { displayPrice } from "../../util/DisplayUtil";

export default function ProductPriceDisplay({ product }) {
  const quantity = product.quantityRequested || product.quantity || 1;
  const unitPrice = product.unitPrice || product.estimatedUnitPrice;
  return (
    <Typography variant="body1" sx={{ ml: 1.5, textAlign: "end" }}>
      {displayPrice(quantity * unitPrice)}
    </Typography>
  );
}
