import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ErrorMessage } from "formik";
import React from "react";

export default function FormSelect({ options, label, size, ...props }) {
  const defaultValue = props.multiple ? [] : "";
  return (
    <FormControl fullWidth size={props.size || "small"}>
      <InputLabel id={props.name + "-label"}>{label}</InputLabel>
      <Select
        labelId={props.name + "-label"}
        id={props.name}
        data-testid={props.name}
        label={label}
        fullWidth
        {...props}
        value={props.value || defaultValue}
        onChange={(e) => {
          props.onChange(e);
        }}
      >
        {options.map((opt, i) => (
          <MenuItem value={typeof opt === "string" ? opt : opt.value} key={i}>
            {typeof opt === "string" ? opt : opt.label}
          </MenuItem>
        ))}
      </Select>
      <ErrorMessage name={props.name}>
        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </FormControl>
  );
}
