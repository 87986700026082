import { useFormikContext } from "formik";
import { useEffect } from "react";

export default function AutoSubmitOnChange() {
  const { isValid, values, dirty, submitForm } = useFormikContext();

  useEffect(() => {
    if (isValid && dirty) {
      void submitForm();
    }
  }, [isValid, values, dirty, submitForm]);

  return null;
}
