import axios from "axios";

export const MASQUERADE_AUTH_BASE_URL =
  process.env.REACT_APP_API_PATH + "/masqueradeAuth";

const authAxiosInstance = axios.create();
authAxiosInstance.defaults.withCredentials = true;

export const MasqueradeAuthService = {
  masqueradeAsUser: (userId) =>
    axios
      .post(MASQUERADE_AUTH_BASE_URL + "/login", { id: userId })
      .then((r) => r.data),
  masqueradeLogout: () =>
    axios.get(MASQUERADE_AUTH_BASE_URL + "/logout").then((r) => r.data),
};
