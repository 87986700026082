import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

export default function FormCheckboxWithLabel({
  name,
  label,
  value,
  ...props
}) {
  return (
    <FormControlLabel
      sx={{ width: "fit-content" }}
      id={name}
      data-testid={name}
      label={label}
      control={
        <Checkbox
          name={name}
          data-testid={name + "-checkbox"}
          checked={value === "true" || value === true ? true : false}
          {...props}
        />
      }
      {...props}
    />
  );
}
