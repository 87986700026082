import moment from "moment";
export function isValidPickupDate(pickupDate, collective) {
  if (!pickupDate) {
    return false;
  }
  // Check if date is a moment object
  if (!pickupDate.isoWeekday) {
    pickupDate = moment(pickupDate);
  }
  switch (collective.subdomain) {
    case "cytest":
      return true;
    case "picklinq":
    default: {
      // For picklinq, only allow Tuesday, Wednesday, and Friday
      const dayOfWeek = pickupDate.isoWeekday();
      return dayOfWeek === 2 || dayOfWeek === 3 || dayOfWeek === 5;
    }
  }
}

export function isValidBringToMarketDay(date, collective) {
  if (!date) {
    return false;
  }
  // Check if date is a moment object
  if (!date.isoWeekday) {
    date = moment(date);
  }
  switch (collective.subdomain) {
    case "cytest":
      return true;
    case "picklinq":
    default: {
      // For picklinq, only allow Tuesday, Thursday, and Friday
      const dayOfWeek = date.isoWeekday();
      return dayOfWeek === 2 || dayOfWeek === 4 || dayOfWeek === 5;
    }
  }
}

export function isPrimaryMarketDay(date, collective) {
  if (!date) {
    return false;
  }
  // Check if date is a moment object
  if (!date.isoWeekday) {
    date = moment(date);
  }
  switch (collective.subdomain) {
    case "cytest":
      return true;
    case "picklinq":
    default: {
      // For picklinq, only allow Tuesday
      const dayOfWeek = date.isoWeekday();
      return dayOfWeek === 2;
    }
  }
}

export function getNextPrimaryMarketDate(collective) {
  const today = moment().startOf("day");
  switch (collective.subdomain) {
    case "picklinq":
    default:
      // Add days until we get to a valid market day
      while (!isPrimaryMarketDay(today, collective)) {
        today.add(1, "day");
      }
      return today;
  }
}

export function isPresaleShoppingAvailable(collective) {
  const nextMarketDate = getNextPrimaryMarketDate(collective);
  switch (collective.subdomain) {
    case "cytest":
      return true;
    case "picklinq":
    default:
      // Market opens at 5pm 5 days before
      const presaleShoppingBegins = nextMarketDate.clone().subtract(5, "days");
      presaleShoppingBegins.set({
        hour: 17,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      // Market closes at 3pm the day before
      const presaleShoppingEnds = nextMarketDate.clone().subtract(1, "day");
      presaleShoppingEnds.set({
        hour: 15,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      // return moment().isBetween(presaleShoppingBegins, nextMarketDate);
      return true;
  }
}

export function isPresaleUploadingAvailableForDate(d, collective) {
  // Check if date is a moment object
  if (!d.isoWeekday) {
    d = moment(d);
  }
  switch (collective.subdomain) {
    case "cytest":
      return true;
    case "picklinq":
    default:
      return true;
    // Presale uploads end at 3pm 5 days before the market
    // const uploadDeadline = d.clone().subtract(5, "days");
    // uploadDeadline.set({ hour: 15, minute: 0, second: 0, millisecond: 0 });
    // return moment().isBefore(uploadDeadline);
  }
}

export function isBeforeCutoffToEditProductRequest(requestDate, collective) {
  if (!requestDate) {
    return false;
  }
  // Check if date is a moment object
  if (!requestDate.isoWeekday) {
    requestDate = moment(requestDate);
  }
  switch (collective.subdomain) {
    case "cytest":
      return true;
    case "picklinq":
    default:
      // For picklinq, disallow editing the Sunday before the market
      const now = moment();

      const startOfWeekForMarket = requestDate.clone().startOf("week");
      return startOfWeekForMarket.isAfter(now);
  }
}
