import axios from "axios";

export const BUSINESS_BASE_URL = process.env.REACT_APP_API_PATH + "/businesses";

const BusinessService = {
  async getBusinesses(searchCriteria) {
    const response = await axios.get(BUSINESS_BASE_URL, {
      params: new URLSearchParams(searchCriteria),
    });
    return response.data;
  },
  async getBusinessById(businessId) {
    const response = await axios.get(BUSINESS_BASE_URL + "/" + businessId);
    return response.data;
  },
  async createBusiness(business) {
    const response = await axios.post(BUSINESS_BASE_URL, business);
    return response.data;
  },
  async updateBusiness(businessId, business) {
    const response = await axios.put(
      BUSINESS_BASE_URL + "/" + businessId,
      business
    );
    return response.data;
  },
  async updateBusinessLogo(businessId, logoFile) {
    const file = logoFile;
    const formData = new FormData();

    formData.append("file", file);
    const response = await axios.post(
      BUSINESS_BASE_URL + "/" + businessId + "/logo",
      formData
    );
    return response.data;
  },
  async getBusinessesByUserId(userId) {
    const response = await axios.get(BUSINESS_BASE_URL + "/user/" + userId);
    return response.data;
  },
  async createBusinessUser(businessUser) {
    const response = await axios.post(
      BUSINESS_BASE_URL + "/user",
      businessUser
    );
    return response.data;
  },
  async updateBusinessUser(businessUser) {
    const response = await axios.put(BUSINESS_BASE_URL + "/user", businessUser);
    return response.data;
  },
  async deleteBusinessUser(businessId, userId) {
    await axios.delete(
      BUSINESS_BASE_URL + "/" + businessId + "/user/" + userId
    );
  },
};

export default BusinessService;
