import Grid from "@mui/material/Unstable_Grid2/Grid2.js";
import { FastField, Field } from "formik";
import React from "react";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField.jsx";
import FormSelect from "../../../common/components/form/FormSelect.jsx";

const BaseProductSearchCriteria = () => {
  return (
    <Grid container sx={{ mb: 1 }} spacing={3} direction="row">
      <Grid>
        <Field as={DebouncedTextField} label="Search..." name="searchText" />
      </Grid>
      <Grid xs="auto">
        <FastField
          as={FormSelect}
          name="activeStatus"
          label="Product Status"
          options={[
            { value: "", label: "All" },
            { value: "active", label: "Active" },
            { value: "inactive", label: "Inactive" },
          ]}
          sx={{ minWidth: "150px" }}
        />
      </Grid>
    </Grid>
  );
};

export default BaseProductSearchCriteria;
