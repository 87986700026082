import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useAlerts } from "../../context/AlertContext";
import CategoryService from "../../service/CategoryService";
import FormSelect from "./FormSelect";

export default function CategoryFormSelect({ ...props }) {
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const { addErrorAlert } = useAlerts();

  React.useEffect(() => {
    CategoryService.getCategories()
      .then((cats) => {
        setCategoryList(
          cats.map((cat) => ({ value: cat.id, label: cat.displayText }))
        );
      })
      .catch((error) => {
        addErrorAlert("Error fetching categories", error);
      })
      .finally(() => setLoading(false));
  }, [addErrorAlert]);

  if (loading) {
    return <Skeleton variant="rectangular" width={200} height={40} />;
  }

  return (
    <FormSelect
      options={categoryList}
      label="Product Categories"
      multiple
      {...props}
    />
  );
}
