import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../common/context/AuthContext";
import EmailNotVerifiedPage from "../../pages/Auth/EmailNotVerifiedPage";
import NoPermissionPage from "../../pages/General/NoPermissionPage";

export function AuthRoute({ children, hasAccess }) {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  React.useEffect(() => {
    if (!currentUser) {
      return navigate("/");
    }
  }, [currentUser, navigate]);

  if (!currentUser) {
    return null;
  }

  if (currentUser && currentUser.emailVerified !== true) {
    return <EmailNotVerifiedPage />;
  }

  if (
    (typeof hasAccess === "function" && !hasAccess(currentUser)) ||
    (typeof hasAccess === "boolean" && !hasAccess)
  ) {
    return <NoPermissionPage />;
  }

  return <>{children}</>;
}
