import { Box, CircularProgress, Typography } from "@mui/material";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  },
  message: {
    marginTop: "20px",
  },
};

export default function ApplicationLoading() {
  return (
    <Box sx={styles.container} data-testid="loading-container">
      <CircularProgress data-testid="loading-spinner" />
      <Typography
        variant="h6"
        sx={styles.message}
        data-testid="loading-message"
      >
        Loading...
      </Typography>
    </Box>
  );
}
