import { Yard } from "@mui/icons-material";
import { Card, CardActionArea, CardMedia, Typography } from "@mui/material";
import React from "react";

function displayPriceRange(minPrice, maxPrice) {
  if (minPrice === maxPrice) {
    return `$${minPrice}`;
  }
  return `$${minPrice}-${maxPrice}`;
}

function displaySoldBy(defaultUom, defaultQuantity) {
  return `${defaultQuantity || ""} ${defaultUom}`;
}

const AdvancedProductSummary = ({ item, onClick }) => {
  const baseProduct = item;

  return (
    <Card
      sx={{ width: "100%", maxWidth: 240, textAlign: "center" }}
      data-testid={"base-product-summary"}
    >
      <CardActionArea onClick={onClick} sx={{ p: 1 }}>
        {baseProduct.productPicturePath && (
          <CardMedia
            sx={{ height: 140 }}
            image={baseProduct.productPicturePath || <Yard />}
            title={baseProduct.name}
          />
        )}
        {!baseProduct.productPicturePath && (
          <Yard
            sx={{
              height: 140,
              width: 140,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        )}

        <Typography
          variant="body1"
          sx={{
            textOverflow: "ellipsis",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
            display: "block",
            textDecoration: "underline",
            width: { xs: 200, sm: 200, md: 300 },
            fontWeight: 500,
          }}
          data-testid="base-product-name"
        >
          {baseProduct.name}
        </Typography>
        <Typography variant="body2">{baseProduct.description}</Typography>
        <Typography variant="body1" fontWeight={800}>
          {displayPriceRange(baseProduct.minPrice, baseProduct.maxPrice)} /{" "}
          {displaySoldBy(baseProduct.defaultUom, baseProduct.defaultQty)}
        </Typography>
        <Typography variant="body1"></Typography>
      </CardActionArea>
    </Card>
  );
};

export default AdvancedProductSummary;
