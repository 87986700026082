import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import PresaleOrderService from "../../../common/service/PresaleOrderService";
import PresaleOrderSummary from "./PresaleOrderSummary";

export default function SearchPresaleOrders() {
  const locationState = useLocation().state;
  const lastSearchedCriteria = locationState?.lastSearchedCriteria;
  const defaultSearchCriteria = lastSearchedCriteria || {
    searchText: "",
    limitToUser: true,
    page: 1,
    itemsPerPage: 10,
  };
  return (
    <Container>
      <Typography variant="h1">Presale Orders</Typography>
      <Box sx={{ maxWidth: 700 }}>
        <PaginatedSearchResults
          fetchSearchResults={PresaleOrderService.getPresaleOrders}
          ResultDisplayComponent={PresaleOrderSummary}
          SearchCriteriaComponent={Box} // No search criteria ui for this page
          defaultSearchCriteria={defaultSearchCriteria}
        />
      </Box>
    </Container>
  );
}
