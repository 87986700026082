import {
  Button,
  Container,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import BaseProductService from "../../../common/service/BaseProductService";
import EditProductGroupPriceDialog from "./EditProductGroupPriceDialog";

export default function EditProductGroupPricing({ baseProduct }) {
  const baseProductId = baseProduct?.id;
  const [pricingGroups, setPricingGroups] = React.useState([]);

  const [selectedPriceGroup, setSelectedPriceGroup] = React.useState(null);

  React.useEffect(() => {
    if (baseProductId && baseProductId !== "new") {
      BaseProductService.getPriceGroupsByProductId(baseProductId).then(
        setPricingGroups
      );
    }
  }, [baseProductId]);

  const getPriceGroupText = (group) => {
    return group.instanceAttributes
      .map((attr) => `${attr.attributeName}: ${attr.attributeValue}`)
      .join(", ");
  };

  return (
    <Container sx={{ pt: 1.5 }}>
      <Grid container justifyContent="space-between">
        <Typography variant="h6">Pricing Groups</Typography>
        <Grid>
          <Button
            onClick={() =>
              setSelectedPriceGroup({
                id: null,
                instanceAttributes: [],
                price: "",
                baseProductId,
              })
            }
            variant="contained"
          >
            Add Pricing Group
          </Button>
        </Grid>
      </Grid>
      {pricingGroups.length === 0 ? (
        <Typography variant="body1">
          No pricing groups have been added
        </Typography>
      ) : (
        <List>
          {pricingGroups.map((group) => (
            <ListItemButton
              data-testid="price-group-summary"
              key={group.id}
              onClick={() => setSelectedPriceGroup(group)}
            >
              <ListItemText
                primary={getPriceGroupText(group)}
                secondary={`Price: $${group.price}`}
              />
            </ListItemButton>
          ))}
        </List>
      )}
      <EditProductGroupPriceDialog
        open={Boolean(selectedPriceGroup)}
        onClose={() => setSelectedPriceGroup(null)}
        baseProduct={baseProduct}
        priceGroup={selectedPriceGroup}
        onCreate={(priceGroups) => {
          setPricingGroups(priceGroups);
          setSelectedPriceGroup(null);
        }}
        onUpdate={(priceGroups) => {
          setPricingGroups(priceGroups);
          setSelectedPriceGroup(null);
        }}
        onDelete={(deletedId) => {
          setPricingGroups(pricingGroups.filter((g) => g.id !== deletedId));
          setSelectedPriceGroup(null);
        }}
      />
    </Container>
  );
}
