import { Box, Container, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary";
import ProductQuantityChip from "../../../common/components/product/ProductQuantityChip";
import { displayPrice } from "../../../common/util/DisplayUtil";
import { usePresaleOrderContext } from "./PresaleOrderFormContext";

export default function PresaleOrderDetails() {
  const { presaleOrder } = usePresaleOrderContext();

  return (
    <Container>
      {presaleOrder && (
        <Grid container spacing={2}>
          <Grid xs={12} sm={5}>
            <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
              <Typography variant="h4">Order Details</Typography>
              <Typography variant="subtitle1">
                Order ID: PS{presaleOrder.id}
              </Typography>
              <Divider />
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">Buyer: </Typography>
                <BusinessDisplay business={presaleOrder.buyerBusiness} />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">Order Placed: </Typography>
                <Typography variant="body1">
                  {moment(presaleOrder.confirmedDate).format("LLL")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">Market Date: </Typography>
                <Typography variant="body1">
                  {moment(presaleOrder.saleDate).format("dddd, LL")}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="h6">Pickup Location: </Typography>
              <Typography variant="body1">
                {presaleOrder.pickupLocation}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="h6">Estimated Total: </Typography>
              <Typography variant="body1" fontWeight="bold">
                {displayPrice(presaleOrder.total)}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={7}>
            <Typography variant="h4">Product in Order</Typography>
            {presaleOrder.orderProducts.length > 0 &&
              presaleOrder.orderProducts.map((product, index) => (
                <ProductInstanceSummary
                  key={product.presaleOrderItemId + "-" + product.quantity}
                  productInstance={product}
                  summaryComponents={[
                    ({ product }) => (
                      <Typography sx={{ mr: 1 }}>
                        {displayPrice(product.unitPrice)}
                      </Typography>
                    ),
                    ProductQuantityChip,
                    ({ product }) => (
                      <Typography sx={{ ml: 2 }} fontWeight="bold">
                        {displayPrice(product.unitPrice * product.quantity)}
                      </Typography>
                    ),
                  ]}
                />
              ))}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
