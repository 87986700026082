import axios from "axios";

export const SALES_LOG_BASE_URL = process.env.REACT_APP_API_PATH + "/salesLog";

const SalesLogService = {
  async getSalesLogs(searchCriteria) {
    const response = await axios.post(
      SALES_LOG_BASE_URL + "/search",
      searchCriteria
    );
    return response.data;
  },
  getSalesLogById: async (id) => {
    const response = await axios.get(SALES_LOG_BASE_URL + "/" + id);
    return response.data;
  },
};

export default SalesLogService;
