import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { createContext, useContext, useState } from "react";
import * as Yup from "yup";
import { FormDatePicker } from "../components/form/FormDatePicker";
import { FormTextField } from "../components/form/FormTextField";
import { ProductInstanceSummary } from "../components/product/ProductInstanceSummary";
import ProductSaleFocusDisplay from "../components/product/ProductSaleFocusDisplay";
import AgedOutService from "../service/AgedOutService";
import { useAlerts } from "./AlertContext";

const AgedOutContext = createContext();

export const useAgedOutContext = () => useContext(AgedOutContext);

export const AgedOutProvider = ({ children }) => {
  const [selectedProductInstance, setSelectedProductInstance] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [changeCount, setChangeCount] = useState(0);
  return (
    <AgedOutContext.Provider
      value={{
        selectedProductInstance,
        setSelectedProductInstance: (prodInst) => {
          setSelectedProductInstance(prodInst);
          setIsEditing(Boolean(prodInst.dateAgedOut));
        },
        changeMadeFlag: changeCount,
      }}
    >
      {children}
      <Dialog
        open={Boolean(selectedProductInstance)}
        onClose={() => setSelectedProductInstance(null)}
        data-testid="aged-out-dialog"
      >
        {Boolean(selectedProductInstance) ? (
          <Formik
            initialValues={{
              dateAgedOut: isEditing
                ? moment(selectedProductInstance).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
              quantity: isEditing ? selectedProductInstance.quantity : 1,
              productInstanceId: selectedProductInstance.productInstanceId,
              supplierId: selectedProductInstance.supplierId,
            }}
            onSubmit={async (values) => {
              try {
                if (isEditing) {
                  const agedOutId = selectedProductInstance.id;
                  await AgedOutService.updateAgedOutStock(agedOutId, values);
                } else {
                  await AgedOutService.createAgedOutStock(values);
                }
              } catch (error) {
                addErrorAlert("Failed to age out stock", error);
              }
              setChangeCount((prev) => prev + 1);
              addSuccessAlert("Product aged out");
              setSelectedProductInstance(null);
            }}
            validationSchema={Yup.object({
              dateAgedOut: Yup.date().required("Required"),
              quantity: Yup.number()
                .required("Required")
                .min(1, "Must be at least 1"),
            })}
          >
            {({ handleSubmit, isSubmitting, setSubmitting }) => (
              <Form>
                <DialogTitle data-testid="aged-out-dialog-title">
                  Age Out Product
                </DialogTitle>
                <DialogContent
                  data-testid="aged-out-dialog-content"
                  sx={{ display: "flex", gap: 2, flexDirection: "column" }}
                >
                  <ProductInstanceSummary
                    productInstance={selectedProductInstance}
                    summaryComponents={[
                      ({ product }) => (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            ml: 2,
                          }}
                        >
                          {product.supplierLogoPath && (
                            <Avatar
                              src={product.supplierLogoPath}
                              alt={product.supplierName}
                            />
                          )}
                          <Typography
                            variant="body2"
                            data-testid="supplier-name"
                          >
                            {product.supplierName}
                          </Typography>
                        </Box>
                      ),
                      ProductSaleFocusDisplay,
                    ]}
                  />

                  <Grid
                    container
                    sx={{ width: "100%" }}
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm="auto">
                      <Field
                        as={FormDatePicker}
                        name="dateAgedOut"
                        label="Aged Out Date"
                        sx={{ width: "150px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <Field
                        as={FormTextField}
                        name="quantity"
                        label="Quantity"
                        type="number"
                        size="medium"
                        sx={{ width: "100px" }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                  <Button
                    onClick={() => setSelectedProductInstance(null)}
                    data-testid="aged-out-dialog-close-button"
                  >
                    Close
                  </Button>
                  {isEditing && (
                    <Button
                      onClick={async () => {
                        setSubmitting(true);
                        try {
                          await AgedOutService.deleteAgedOutStock(
                            selectedProductInstance.id
                          );
                          setChangeCount((prev) => prev + 1);
                          addSuccessAlert("Aged out product deleted");
                          setSelectedProductInstance(null);
                        } catch (error) {
                          addErrorAlert(
                            "Failed to delete aged out stock",
                            error
                          );
                        } finally {
                          setSubmitting(false);
                        }
                      }}
                      data-testid="aged-out-dialog-delete-button"
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    data-testid="aged-out-dialog-submit-button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {isEditing ? "Update" : "Age Out"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        ) : (
          <Skeleton variant="rectangular" height={100} />
        )}
      </Dialog>
    </AgedOutContext.Provider>
  );
};
