import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults.jsx";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary.jsx";
import ProductQuantityChip from "../../../common/components/product/ProductQuantityChip.jsx";
import ProductSaleFocusDisplay from "../../../common/components/product/ProductSaleFocusDisplay.jsx";
import { useAgedOutContext } from "../../../common/context/AgedOutContext.jsx";
import AgedOutService from "../../../common/service/AgedOutService.js";
import StockLogService from "../../../common/service/StockLogService.js";
import AgedOutStockSearchCriteria from "./AgedOutStockSearchCriteria.jsx";
import ManageAgedOutStockSearchCriteria from "./ManageAgedOutStockSearchCriteria.jsx";

function AgedOutProductInstanceSummary({ item, ...props }) {
  return (
    <ProductInstanceSummaryAdminInventoryView
      item={item}
      sx={{ backgroundColor: "#f9eed4" }}
      {...props}
      data-testid="aged-out-product-instance-summary"
    />
  );
}

function ProductInstanceSummaryAdminInventoryView({ item, ...props }) {
  const { setSelectedProductInstance } = useAgedOutContext();
  return (
    <ProductInstanceSummary
      productInstance={item}
      {...props}
      onClick={() => setSelectedProductInstance(item)}
      summaryComponents={[
        ({ product }) => (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            {product.supplierLogoPath && (
              <Avatar
                src={product.supplierLogoPath}
                alt={product.supplierName}
              />
            )}
            <Typography variant="body2" data-testid="supplier-name">
              {product.supplierName}
            </Typography>
          </Box>
        ),
        ProductSaleFocusDisplay,
        ProductQuantityChip,
      ]}
    />
  );
}

const ManageAgedOutStock = () => {
  const { changeMadeFlag } = useAgedOutContext();
  const locationState = useLocation().state;
  const lastSearchedCriteria = locationState?.lastSearchedCriteria;
  const invSearchRef = useRef();
  const agedOutSearchRef = useRef();
  const defaultSearchCriteria = lastSearchedCriteria || {
    searchText: "",
    supplierId: null,
    dateAgedOut: moment().format("YYYY-MM-DD"),
    dateStockedStart: moment().subtract(10, "days").format("YYYY-MM-DD"),
    dateStockedEnd: moment().format("YYYY-MM-DD"),
    status: "SUBMITTED",
    orderBy: "BringToMarket",
    businesses: [],
    saleFocus: "",
    page: 1,
    itemsPerPage: 10,
  };

  React.useEffect(() => {
    if (changeMadeFlag && agedOutSearchRef.current) {
      agedOutSearchRef.current.rerunSearch();
    }
  }, [changeMadeFlag]);

  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h2" gutterBottom>
        Age Out Inventory
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Inventory
          </Typography>
          <PaginatedSearchResults
            ref={invSearchRef}
            fetchSearchResults={StockLogService.getInventoryForAgeOut}
            ResultDisplayComponent={ProductInstanceSummaryAdminInventoryView}
            SearchCriteriaComponent={ManageAgedOutStockSearchCriteria}
            defaultSearchCriteria={defaultSearchCriteria}
            validationSchema={Yup.object().shape({
              searchText: Yup.string(),
              // Ensure dateStockedStart is before dateStockedEnd
              dateStockedStart: Yup.date()
                .nullable()
                .typeError("Invalid Date")
                // Only allow dates after 2000
                .min("2000-01-01")
                .test(
                  "before-end-date-range",
                  "Start date must be before end date",
                  function (value) {
                    const endDate = this.parent.dateStockedEnd;
                    if (!value || !endDate) {
                      return true;
                    }
                    return moment(value).isBefore(moment(endDate));
                  }
                ),
              dateStockedEnd: Yup.date()
                .nullable()
                .typeError("Invalid Date")
                .min("2000-01-01"),
              businesses: Yup.array(),
            })}
            normalizeSearchCriteria={(criteria) => {
              if (
                criteria.dateStockedStart &&
                typeof criteria.dateStockedStart !== "string"
              ) {
                criteria.dateStockedStart =
                  criteria.dateStockedStart.format("YYYY-MM-DD");
              }
              if (
                criteria.dateStockedEnd &&
                typeof criteria.dateStockedEnd !== "string"
              ) {
                criteria.dateStockedEnd =
                  criteria.dateStockedEnd.format("YYYY-MM-DD");
              }
              return criteria;
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Aged Out Product
          </Typography>
          <PaginatedSearchResults
            ref={agedOutSearchRef}
            fetchSearchResults={AgedOutService.searchAgedOutStock}
            ResultDisplayComponent={AgedOutProductInstanceSummary}
            SearchCriteriaComponent={AgedOutStockSearchCriteria}
            defaultSearchCriteria={defaultSearchCriteria}
            validationSchema={Yup.object().shape({
              dateAgedOut: Yup.date()
                .required()
                .nullable()
                .typeError("Valid date required"),
            })}
            normalizeSearchCriteria={(criteria) => {
              if (
                criteria.dateAgedOut &&
                typeof criteria.dateAgedOut !== "string"
              ) {
                criteria.dateAgedOut =
                  criteria.dateAgedOut.format("YYYY-MM-DD");
              }
              return criteria;
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ManageAgedOutStock;
