import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../common/context/AuthContext";

export function NonAuthRoute({ children }) {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  React.useEffect(() => {
    if (currentUser) {
      return navigate("/app");
    }
  }, [currentUser, navigate]);

  return <>{children}</>;
}
