import { Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import BaseProductAutocomplete from "../../../common/components/form/BaseProductAutocomplete";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";

export default function ManageSalesLogsSearchCriteria() {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        gap: 2,
      }}
      elevation={0}
    >
      <Field
        as={DebouncedTextField}
        name="invoiceNumber"
        label="Invoice Number"
        sx={{ maxWidth: 150, width: "100%" }}
        size="small"
      />
      <Field
        as={BusinessAutocomplete}
        name="buyerBusinesses"
        label="Buyer Business"
        multiple
        sx={{ maxWidth: 300, width: "100%" }}
      />
      <Field
        as={FormDatePicker}
        name="saleDate"
        label="Sale Date"
        sx={{ maxWidth: 200, width: "100%" }}
        size="small"
      />
      <Field
        as={BaseProductAutocomplete}
        name="productInSale"
        label="Product in Sale"
        sx={{ maxWidth: 350, width: "100%" }}
      />
    </Paper>
  );
}
