import axios from "axios";

export const BASEPRODUCTS_BASE_URL =
  process.env.REACT_APP_API_PATH + "/baseProducts";

const BaseProductService = {
  async getBaseProducts(searchCriteria) {
    const response = await axios.get(BASEPRODUCTS_BASE_URL, {
      params: new URLSearchParams(searchCriteria),
    });
    return response.data;
  },
  async getBaseProductById(baseProductId, includePriceGroups = false) {
    const params = {};
    if (includePriceGroups) {
      params.includePriceGroups = true;
    }
    const response = await axios.get(
      BASEPRODUCTS_BASE_URL + "/" + baseProductId,
      { params }
    );
    return response.data;
  },
  async getPriceGroupsByProductId(baseProductId) {
    const response = await axios.get(
      BASEPRODUCTS_BASE_URL + "/price-groups/" + baseProductId
    );
    return response.data;
  },
  async addProductPriceGroup(priceGroup) {
    const response = await axios.post(
      BASEPRODUCTS_BASE_URL + "/price-groups",
      priceGroup
    );
    return response.data;
  },
  async updateProductPriceGroup(priceGroup) {
    const response = await axios.put(
      BASEPRODUCTS_BASE_URL + "/price-groups/" + priceGroup.id,
      priceGroup
    );
    return response.data;
  },
  async deleteProductPriceGroup(priceGroupId) {
    const response = await axios.delete(
      BASEPRODUCTS_BASE_URL + "/price-groups/" + priceGroupId
    );
    return response.data;
  },
  async createBaseProduct(baseProduct) {
    const response = await axios.post(BASEPRODUCTS_BASE_URL, baseProduct);
    return response.data;
  },
  async updateBaseProduct(baseProductId, baseProduct) {
    const response = await axios.put(
      BASEPRODUCTS_BASE_URL + "/" + baseProductId,
      baseProduct
    );
    return response.data;
  },
  async updateBaseProductImage(baseProductId, productImageFile) {
    const file = productImageFile;
    const formData = new FormData();

    formData.append("file", file);
    const response = await axios.post(
      BASEPRODUCTS_BASE_URL + "/" + baseProductId + "/product-image",
      formData
    );
    return response.data;
  },
};

export default BaseProductService;
