import axios from "axios";

export const COLLECTIVES_BASE_URL =
  process.env.REACT_APP_API_PATH + "/collectives";
export const PUBLIC_COLLECTIVES_BASE_URL =
  process.env.REACT_APP_API_PATH + "/collectivesPublic";

const CollectiveService = {
  async getCurrentCollectiveInfo() {
    const response = await axios.get(PUBLIC_COLLECTIVES_BASE_URL + "/current");
    return response.data;
  },
};

export default CollectiveService;
